import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.css']
})
export class CropComponent implements OnInit {

  imageUrl:any = "/assets/img/example.jpg";
  loadAPI: Promise<any>;

  constructor() {
    this.loadAPI = new Promise<any>((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  ngOnInit(): void {

  }

  public loadScript(){
    let isFound = false;
    let scripts = document.getElementsByTagName("script");
    for(let i = 0; i < scripts.length; i++){
      if(scripts[i].getAttribute('scr') != null && scripts[i].getAttribute('src').includes("loader")){
        isFound = true;
      }
    }
    if (!isFound) {
      const dynamicScripts = ["/assets/js/cropper.min.js","/assets/js/cropper.js"];

      for (let i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts [i];
        node.type = 'text/javascript';
        node.async = false;
        node['charset'] = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

}
