import { Component, OnInit } from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-steps-card',
  templateUrl: './steps-card.component.html',
  styleUrls: ['./steps-card.component.css']
})
export class StepsCardComponent implements OnInit {

  isResponsiveMode: boolean = false;
  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      this.isResponsiveMode = true;
    }
  }

}
