import {Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router} from '@angular/router';
import {ModalDismissReasons, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from './loading.service';
import {element} from 'protractor';
import {ViewAppsComponent} from './admin/view-apps/view-apps.component';
import {ViewProfilesComponent} from './admin/view-profiles/view-profiles.component';
import {Application} from './models/application';
import {AdminService} from './services/adminServices/admin-service.service';
import {CommonService} from './providers/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnChanges{

  userId:number;
  @Input() sourceData = new Array<Application>();
  childName:string;
  childObject: any;

  constructor(
               private route: ActivatedRoute,
               public loadingService: LoadingService,
               private router:Router,
               private service: CommonService) {

    router.events.subscribe((val) => {
      if (val instanceof ActivationEnd) {
        if(val.snapshot.component['name'] !== "IndexComponent"){
          this.childName =  val.snapshot.component['name'];
          this.childObject =  val.snapshot.component;
        }
      }
    });
  }




  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.userId = Number(params.get('id'));
    });

  }
  ngOnChanges(changes: SimpleChanges){
    let data = changes.sourceData.currentValue;
    let component = this.childObject;
    let componentName = this.childName;

    //const temp = <ViewAppsComponent>component.prototype.getNewRecordLog;

    //console.log('test', temp);
    // switch (componentName){
    //  // case "ViewAppsComponent": <ViewAppsComponent>component.prototype.getNewRecordLog(data);
    //   case "ViewAppsComponent":
    //    let temp =  new ViewAppsComponent(null,null,null,null,null,null, this.service);
    //     this.service.sendData(data);
    //     temp.ngOnChanges(changes);
    //
    //     //temp.getNewRecordLog(data);
    //      break;
    //   // case "ViewUsersComponent": this.headerName = "View Users"; this.isHeaderEnable = true; break;
    //   // case "ViewProfilesComponent": this.headerName = "View Doctors"; this.isHeaderEnable = true; break;
    //   // default: this.headerName = ""; this.isHeaderEnable = false; break;
    // }

    console.log('componentName', componentName);


  }

  sendMessage(): void {
    // send message to subscribers via observable subject
    this.service.sendUpdate('Message from Sender Component to Receiver Component!');
  }

  changeLoadText(CompRef:any) {
    let ChildText:string = CompRef?.loadingText;
    console.log(ChildText); // 'some text'
  }
  isUserChanged(evt){
    console.log(evt);
  }
//onOutletLoaded
//   routerOnChange(component: ViewAppsComponent | ViewProfilesComponent) {
//     if (component instanceof ViewAppsComponent) {
//       component.getNewRecordLog(this.sourceData);
//     // } else if (component instanceof MyComponent2) {
//     //   component.anotherInput = 456;
//     }
//   }

}
