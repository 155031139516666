import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError, timer} from 'rxjs';
import {catchError, delayWhen, map, retry, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FileApiService {

  httpOptionsDirectory = {
    headers: new HttpHeaders({
      'Content-Type': 'application/octet-stream'
    })
  };

  apiURL = 'https://dashboard.daalortho.com/api/file/';
  public serverURL = "https://dashboard.daalortho.com/api/uploads/";

  //apiURL = 'http://localhost:3000/api/file/';
  //public serverURL = "http://localhost:3000/api/uploads/";


  // Define API
  constructor(private http: HttpClient) {
  }

  upload(file:any, user:string, appId:string):Observable<any>{
    return this.http.put<any>(this.apiURL + 'upload/'+ user + "/" + appId, file)
      .pipe(
	    tap(val => console.log("Before" + file)),
        delayWhen(() => timer(100)),
        retry(2),
        catchError(this.handleError)
      )
  }

  createDirectory(userDirectory:string, appId?:string):Observable<any>{
    return this.http.post<any>(this.apiURL + 'createDirectory/'+userDirectory+"/"+appId, this.httpOptionsDirectory)
      .pipe(map(result => result));
  }

  getDirectory(userDirectory:string, appId?:string):Observable<any>{
    return this.http.get<any>(this.apiURL + 'getDirectory/' + userDirectory + "/" + appId)
      .pipe(map(result => result));
  }



  download(url: string): Observable<any> {
    return this.http.get<any>(url)
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
