import {Application} from './application';

export class Documents {
  idDocument: number;
  Code: string;
  Name: string;
  Type: string;
  isLabSlip:boolean;
  RolId:number;
  isActive:boolean;
  createdAt:Date;
  updatedAt:Date;
  url:string;
  File:File;
  CountRows:number;


  Application:Application;
}
