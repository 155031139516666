import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {AlertService} from '../..';
import {LoginService} from '../../services/login.service';
import {NotificationService} from '../../providers/notification.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css']
})
export class EmailConfirmationComponent implements OnInit {
  signUpForm:FormGroup;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  code:string;
  codeFrom:string;
  counter:number=70;
  user:string;
  userId:number;
  email:string;
  isOver:boolean=false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminServiceService:AdminService,
              private loginService:LoginService,
              private notifyService : NotificationService,
              protected alertService: AlertService,
              fb: FormBuilder) {

    this.signUpForm = fb.group({
      'code': [null, Validators.required]
    });

  }

  ngOnInit(): void {

    this.email = this.route.snapshot.paramMap.get('email');
    this.user = JSON.parse(localStorage.getItem('userInfo')).userName;
    this.userId = JSON.parse(localStorage.getItem('userInfo')).idUser;
    this.code = localStorage.getItem('code');
    this.getEmailByUserId(this.userId);
  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.isCodeValid();
    }
  }

  isCodeValid():void{
   /*the code must to be stay in localstorage until validate with email'scode coming*/
    //const getCode = localStorage.getItem('code');
    if(this.codeFrom === this.code && this.code != undefined){
      localStorage.removeItem('code');
      this.notifyService.showHTMLMessage("Code Valid, You can Login in with your username and password","Success");
      this.adminServiceService.desactivateAccount(this.userId,true).subscribe({
      complete: () => {
        this.router.navigate(['/']).then(() => {});
      }
      });

    }else{
      this.notifyService.showWarning("Be Carefully on take this decision more than 2 times");
      location.reload();
    }
  }

  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); }
    });
  };
  reSend(){
    this.notifyService.showInfo("Your new code has been send in your email","Success");
    this.notifyService.showWarning("If you have troubles please contact to info@daalortho.com");
    const code = this.loginService.generateCode();
    localStorage.setItem('code',code);
    this.adminServiceService.sendEmailConfirmation(this.email,code).subscribe(res => {
      if(res){
        location.reload();
      }
    });
  }

  handleEvent(evt){
    if(evt.status === 3){
      this.isOver = true;
    }
  }

  getEmailByUserId(userId:number){
    this.adminServiceService.getUserByUserId(userId).subscribe(res =>{
      console.log(res);
    });
  }

}
