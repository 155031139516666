
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AuthServiceConfig, GoogleLoginProvider, SocialLoginModule} from 'angular4-social-login';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './login/login.component';
import {NvarHeaderComponent} from './body/nvar/nvar-header/nvar-header.component';
import {NvarLeftComponent} from './body/nvar/nvar-left/nvar-left.component';
import {FooterComponent} from './body/footer/footer.component';
import {ApplicationComponent} from './doctor/application/application.component';
import {IndexComponent} from './index/index.component';
import {DocumentsDirective} from './documents.directive';
import {DocumentComponent} from './common/document/document.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {RecordLogComponent} from './doctor/record-log/record-log.component';
import {AppComponent} from './app.component';
import {WindowScrollDirectiveDirective} from './window-scroll-directive.directive';
import {DndDirective} from './dnd.directive';
import {ProfileComponent} from './profile/profile.component';
import {ViewAppsComponent} from './admin/view-apps/view-apps.component';
import {ViewProfilesComponent} from './admin/view-profiles/view-profiles.component';

import {ErrorTemplateComponent} from './error-template/error-template.component';
import {SearchBarComponent} from './body/search-bar/search-bar.component';
import {NotificationsComponent} from './body/notifications/notifications.component';
import {SignInComponent} from './login/sign-in/sign-in.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {ProfileEditComponent} from './profile/profile-edit/profile-edit.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StepsCardComponent} from './doctor/steps-card/steps-card.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';
import {NewProfileComponent} from './admin/new-profile/new-profile.component';
import {MultiAlertsComponent} from './common/multi-alerts/multi-alerts.component';
import {AlertModule} from './_alert/alert.module';
import {PushNotificationsComponent} from './common/push-notifications/push-notifications.component';
import {ToastrModule} from 'ngx-toastr';
import {OfficeComponent} from './doctor/office/office.component';
import {NewOfficeComponent} from './doctor/office/new-office/new-office.component';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {DaalChatComponent} from './common/daal-chat/daal-chat.component';
import {SignUpComponent} from './login/sign-up/sign-up.component';
import {ViewOfficesComponent} from './admin/view-offices/view-offices.component';
import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireStorageModule} from 'angularfire2/storage';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {ContactComponent} from './admin/contact/contact.component';
import {LeftCardComponent} from './body/nvar/left-card/left-card.component';
import {ModalChatComponent} from './admin/modal-chat/modal-chat.component';
import {ExampleChatComponent} from './common/example-chat/example-chat.component';


//material
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {ManageChatsComponent} from './admin/manage-chats/manage-chats.component';

import {NgxPaginationModule} from 'ngx-pagination';
import {EmailConfirmationComponent} from './common/email-confirmation/email-confirmation.component';
import {CountdownModule} from 'ngx-countdown';
import {ChangePasswordComponent} from './common/change-password/change-password.component';
import {CategoriesComponent} from './web/categories/categories.component';
import {SectionsComponent} from './web/categories/sections/sections.component';
import {HomeComponent} from './web/categories/sections/home/home.component';
import {AboutUsComponent} from './web/categories/sections/about-us/about-us.component';
import {ContactUsComponent} from './web/categories/sections/contact-us/contact-us.component';
import {DigitalComponent} from './web/categories/sections/digital/digital.component';
import {DocumentsComponent} from './web/categories/sections/documents/documents.component';
import {NewsLetterComponent} from './web/categories/sections/common/news-letter/news-letter.component';
import {HeaderWebComponent} from './web/categories/sections/common/header/header-web.component';
import {FooterWebComponent} from './web/categories/sections/common/footer/footer-web.component';
import {BannerWebComponent} from './web/categories/sections/common/banner-web/banner-web.component';
import {ScheduleSectionComponent} from './web/categories/sections/common/schedule-section/schedule-section.component';
import {StructureComponent} from './web/structure/structure.component';
import {FormElementDirective} from './form-element.directive';
import {NgWizardConfig, NgWizardModule, THEME} from 'ng-wizard';
import {ShContextMenuModule} from 'ng2-right-click-menu';
import {HandlerComponent} from './web/structure/handler/handler.component';
import {CropComponent} from './common/crop/crop.component';
import {TruncatePipe} from './truncate.pipe';
import {ModalTrackingComponent} from './admin/modal-tracking/modal-tracking.component';
import {SettingsComponent} from './admin/settings/settings.component';
import {NewLoginComponent} from './login/new-login/new-login.component';
import {ViewUsersComponent} from './admin/view-users/view-users.component';

import { LoadingInterceptor } from './loading.interceptor';
import {NewDaalChatComponent} from './common/daal-chat/new-daal-chat.component';
import {LeftChatComponent} from './common/daal-chat/left-chat/left-chat.component';
import {ConversationComponent} from './common/daal-chat/container/conversation.component';
import {CasesDeliveredComponent} from './admin/cases-delivered/cases-delivered.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


//declare id for the google login
const google_oauth_client_id:string="1022019676725-vrikm47vmm60snqbiqcigtl2pf2lpl9p.apps.googleusercontent.com";
//configure it in a object
let config = new AuthServiceConfig([{
  id:GoogleLoginProvider.PROVIDER_ID,
  provider: new GoogleLoginProvider(google_oauth_client_id)
}]);

export function provideConfig(){
  return config;
}

/*Exporting socket io for chat side*/
const configChat: SocketIoConfig = { url: 'http://localhost:3002', options: {} };

const ngWizardConfig: NgWizardConfig = {
     theme: THEME.default
}

// @ts-ignore
@NgModule({
  declarations: [
    IndexComponent,
    LoginComponent,
    NvarHeaderComponent,
    NvarLeftComponent,
    FooterComponent,
    ApplicationComponent,
    DocumentsDirective,
    DocumentComponent,
    RecordLogComponent,
    AppComponent,
    WindowScrollDirectiveDirective,
    DocumentComponent,
    DndDirective,
    ProfileComponent,
    ViewAppsComponent,
    ViewProfilesComponent,
    ErrorTemplateComponent,
    SearchBarComponent,
    NotificationsComponent,
    SignInComponent,
    ProfileEditComponent,
    StepsCardComponent,
    NewProfileComponent,
    MultiAlertsComponent,
    PushNotificationsComponent,
    OfficeComponent,
    NewOfficeComponent,
    DaalChatComponent,
    SignUpComponent,
    ViewOfficesComponent,
    ContactComponent,
    LeftCardComponent,
    ModalChatComponent,
    ExampleChatComponent,
    ManageChatsComponent,
    NewDaalChatComponent,
    EmailConfirmationComponent,
    ChangePasswordComponent,
    CategoriesComponent,
    SectionsComponent,
    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    DigitalComponent,
    DocumentsComponent,
    NewsLetterComponent,
    HeaderWebComponent,
    FooterWebComponent,
    BannerWebComponent,
    ScheduleSectionComponent,
    StructureComponent,
    FormElementDirective,
    HandlerComponent,
    CropComponent,
    TruncatePipe,
    ModalTrackingComponent,
    SettingsComponent,
    NewLoginComponent,
    ViewUsersComponent,
    LeftChatComponent,
    ConversationComponent,
    CasesDeliveredComponent

  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SocialLoginModule,
    AppRoutingModule,
    NgxExtendedPdfViewerModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatTooltipModule,
    NgbModule,
    AlertModule,
    ToastrModule.forRoot(),
    SocketIoModule.forRoot(configChat),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatInputModule,
    MatButtonModule,
    ScrollingModule,
    MatSnackBarModule,
    MatListModule,
    MatDialogModule,
    ScrollToModule.forRoot(),
    FilterPipeModule,
    NgxPaginationModule,
    CountdownModule,
    NgWizardModule.forRoot(ngWizardConfig),
    ShContextMenuModule,
    MatDatepickerModule, 
    MatNativeDateModule
],
  entryComponents:[
    NewProfileComponent,
    NewOfficeComponent,
    DaalChatComponent,
    ViewOfficesComponent,
    ContactComponent,
    ModalChatComponent,
    ExampleChatComponent,
    ModalTrackingComponent
  ],
  exports: [
    RouterModule,
    IndexComponent,
    NvarLeftComponent,
    NewProfileComponent,
    SignInComponent,
    AppComponent,
    NewOfficeComponent,
    DaalChatComponent,
    NewDaalChatComponent,
    ViewOfficesComponent,
    ContactComponent,
    ModalChatComponent,
    ModalTrackingComponent,
    ExampleChatComponent,
    NewLoginComponent],
 providers: [
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    {provide: AuthServiceConfig, useFactory:provideConfig},
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
