// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDuRnLbpck462An91k4scVGdgmOG-b4kkw',
    authDomain: 'firechat-84926.firebaseapp.com',
    databaseURL: "https://firechat-84926.firebaseio.com",
    projectId: "firechat-84926",
    storageBucket: "firechat-84926.appspot.com",
    messagingSenderId: "908385566865",
    appId: "1:908385566865:web:a11ff324aca60b47d73f30"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
