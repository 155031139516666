import {Component, OnInit} from '@angular/core';
import {HandlerModel} from '../../../../structure/model/handler-model';


@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.css','../../../categories.component.css']
})
export class NewsLetterComponent implements OnInit {

  public className: string = "newsletter-section";
  public collectionName: string ="news-letter";
  handlerModel:HandlerModel = new HandlerModel();

  constructor() { }

  ngOnInit(): void {
    this.handlerModel = new  HandlerModel();
  }


  getElementSelected(objectSelected):void{
    if(objectSelected){
      this.handlerModel = objectSelected;
      this.handlerModel.sectionName = this.className;
      this.handlerModel.collectionName = this.collectionName;
    }
  }

  getNewData($event){
    this.handlerModel.hasData = $event;
  }

  onClick(event){
    if(event !== undefined || event.data !=="" || (event.handlerModel !== undefined && event.handlerModel.text !== "")){
      this.handlerModel.hasData = true;
    }
  }

  onCancel(){
    this.handlerModel.text = "";
    this.handlerModel.hasData = false;
  }



}
