import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {AlertService} from '../..';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private route: ActivatedRoute, private router: Router, private adminServiceService:AdminService,protected alertService: AlertService){}
  public profile: any;
  userId:number;

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.userId = Number(params.get('id'));
    });

    this.adminServiceService.getByUserId(this.userId).subscribe((data: {}) => {
      this.profile = data;
    });
  }

  back(){
    this.router.navigate(['/index/view-profiles']);
  }

  save(){
    this.adminServiceService.updateProfile(this.profile.idProfile,this.profile).subscribe((res: {}) => {
      if(res === true){
        this.adminServiceService.updateAddress(this.profile.AddressId,this.profile.address).subscribe((address: {}) => {
          if(address === true){
            this.alertService.success("Changes Saved",{id:'alert-2',autoClose:this.options.autoClose});
          }
        });
      }
    });
  }

}
