
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, pipe, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {resizeUpTo535px} from 'ngx-extended-pdf-viewer/lib/ResponsiveCSSSimulation';
import {Doctor} from '../../models/doctor';
import {User} from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  // Define API
  apiURL = 'https://dashboard.daalortho.com/api/';

  //apiURL = 'http://localhost:3000/api/';

  sourceData:any[] = [];

   constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/json'
    })
  };

  //api/user/getAll
  getAllUsers():Observable<any>{
    return this.http.get<any>(this.apiURL+'user/getAll')
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  };

  //api/application/getAll
  getAllApplications():Observable<any>{
    return this.http.get<any>(this.apiURL+'application/getAll')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getAllApplicationsDelivered():Observable<any>{
    return this.http.get<any>(this.apiURL+'application/getAllDelivered')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getStatus():Observable<any>{
    return this.http.get<any>(this.apiURL+'application/getStatus')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getByUserId(userId):Observable<any>{
     return this.http.get<any>(this.apiURL + 'profile/getByUserId/' + userId)
       .pipe(
         retry(1),
         catchError(this.handleError)
       )
  };

  getUserByProfileId(profileId:number):Observable<any>{
    return this.http.get<any>(this.apiURL + 'profile/getUserByProfileId/' + profileId)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  geUserByUserName(userName:string, password:string):Observable<any>{
    if(!password){
    return this.http.get<any>(this.apiURL + 'user/geByUserName/' + userName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }else{
      return this.http.get<any>(this.apiURL + 'user/geByUserName/' + userName + '/' + password)
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
    }
  };

  updateProfile(idProfile:number,profile:any):Observable<any>{
    return this.http.put<any>(this.apiURL + 'profile/update/' + idProfile, profile, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  updateAddress(addressId:number,address:any):Observable<any>{
    return this.http.put<any>(this.apiURL + 'address/update/'+addressId, address,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  desactivateAccount(userId:number,status:boolean):Observable<any>{
     return this.http.put<any>(this.apiURL + 'user/changeStatus/'+userId +'/'+ status,this.httpOptions)
       .pipe(
         retry(1),
         catchError(this.handleError)
       )
  };

  desactivateApplication(idApplication:number):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/update/'+idApplication,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  deliverApp(idApplication:number,isDelivered:boolean):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/delivered/' +idApplication+'/'+isDelivered,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  setInLabApplication(idApplication:number):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/setInLabApplication/' +idApplication,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  updateDateDelivered(DateDelivered:any,idApplication:number):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/updateDateDelivered/' +DateDelivered+'/'+idApplication,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  isDownloaded(idApplication:number,hasDownloaded:any):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/isDownloaded/' +idApplication+'/'+hasDownloaded,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getDocumentsByApplicationId(idApplication:number):Observable<any>{
    return this.http.get<any>(this.apiURL + 'document/getByApplicationId/'+idApplication)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  //getUsersToMigrate
  getUsersToMigrate():Observable<any>{
    return this.http.get<any>(this.apiURL+'user/getUsersToMigrate')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  createProfile(profileModel:Doctor):Observable<Doctor>{
    return this.http.post<Doctor>(this.apiURL + 'profile/create/'+profileModel.UserId,profileModel,this.httpOptions)
      .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  createUer(user:any):Observable<User>{
    return this.http.post<User>(this.apiURL + 'user/create/',user,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  sendEmailConfirmation(email:string,code:string):Observable<any>{
    const data = {email: email, code: code}
    return this.http.post<any>(this.apiURL + 'user/sendEmail/',data,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getUserByUserId(idUser:number):Observable<any>{
    return this.http.get<any>(this.apiURL + 'profile/getUserByUserId/'+idUser)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  isEmailExists(email:string):Observable<boolean>{
    return this.http.get<boolean>(this.apiURL + 'profile/isEmailExists/'+email)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getProfileByEmail(email:string):Observable<any>{
    return this.http.get<any>(this.apiURL + 'profile/getProfileByEmail/'+email,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  updateById(userId:number,userModel:any):Observable<any>{
    return this.http.put<any>(this.apiURL + 'user/updateById/' +userId,userModel,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };


/* TRACKING NUMBER SECTION*/
  updateTrackingNumber(idApplication:string, trackingNumber:string):Observable<any>{
    const data = {trackingNumber: trackingNumber};
    return this.http.put<any>(this.apiURL + 'application/updateTrackingNumber/'+idApplication,data,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getByApplicationByApplicationId(ApplicationId:string):Observable<any>{
    return this.http.get<any>(this.apiURL + 'application/getByApplicationByApplicationId/'+ApplicationId,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };



  /*SETTINGS*/
  getNote():Observable<any>{
    return this.http.get<any>(this.apiURL + 'setting/getNote/',this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  updateNote(id: number, reason: string):Observable<any>{
    const data = {reason: reason};
    return this.http.put<any>(this.apiURL + 'setting/updateNote/' + id,data,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  /*CHAT SECTION*/

  getAllChats():Observable<any>{
    return this.http.get<any>(this.apiURL+'chat/getAll')
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  };
  getChatDetailByUserId(idUser:number):Observable<any>{
    return this.http.get<any>(this.apiURL + 'chat/getChatDetailByUserId/'+idUser)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  };

  getChatDetailByUid(uid:string):Observable<any>{
    return this.http.get<any>(this.apiURL + 'chat/getChatDetailByUid/'+uid)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  };


  updateChatByUserId(userId:number,chatModel:any):Observable<any>{
    return this.http.put<any>(this.apiURL + 'chat/updateByUserId/' +userId,chatModel,this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  };

  createChatDetail(chatModel:any):Observable<User>{
    return this.http.post<User>(this.apiURL + 'chat/create/',chatModel,this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  }

  updateDueDate(DueDate:any,idApplication:number):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/updateDueDate/' +DueDate+'/'+idApplication,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

 updateInvoice(idApplication:number):Observable<any>{
    return this.http.put<any>(this.apiURL + 'application/updateInvoice/' +idApplication,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };


  // Error handling
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
