import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable,throwError, timer } from 'rxjs';
import {catchError, delayWhen, retry, tap} from 'rxjs/operators';
import {Application} from '../../models/application';
import {Documents} from '../../models/documents';
import {Office} from '../../models/office';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  // Define API
  apiURL = 'https://dashboard.daalortho.com/api/';
// apiURL = 'http://localhost:3000/api/';


  public dataSource:any;
  public screen:string;

  constructor(private http: HttpClient) { }

  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/json'
    })
  };
 public office = new Office();
//api/application/getAll
  getAll():Observable<any>{
    return this.http.get<any>(this.apiURL+'profile/getAll')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getApplicationsByProfileId(idProfile:number):Observable<any>{
    return this.http.get<any>(this.apiURL+'application/getApplicationsByProfileId/'+idProfile)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  createApplication(profileId:number,application:Application): Observable<Application> {
    return this.http.post<Application>(this.apiURL + 'application/create/'+profileId, application, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  createDocument(appId:number,document:Documents): Observable<Documents> {
    return this.http.post<Documents>(this.apiURL + 'document/create/'+ appId, document, this.httpOptions)
      .pipe(
	    tap(val => console.log("Before" + document)),
        delayWhen(() => timer(100)),
        retry(1),
        catchError(this.handleError)
      )
  };

  /*OFFICE SECTION*/
  getAllOffices():Observable<any>{
    return this.http.get<any>(this.apiURL+'office/getAll')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getOfficesByProfileId(idProfile:number):Observable<any>{
    return this.http.get<any>(this.apiURL+'office/getOfficesByProfiledId/'+idProfile)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  createOffice(office:any): Observable<any> {
    return this.http.post<any>(this.apiURL + 'office/create', office, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  updateOffice(idOffice:number,office:Office):Observable<Office>{
    return this.http.put<Office>(this.apiURL + 'office/update/'+idOffice, office,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  deleteOffice(idOffice:number):Observable<any>{
    return this.http.delete<any>(this.apiURL + 'office/delete/' +  idOffice, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  updateNickNameByOffice(idOffice:number,office:Office):Observable<Office>{
    return this.http.put<Office>(this.apiURL + 'office/updateNickNameByOffice/'+idOffice, office,this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getAllNickNames():Observable<any>{
    return this.http.get<any>(this.apiURL+'profile/getAllNickNames')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };
  sendOfficeModel(model){
    this.office = model;
  }

  getOfficeModel(){
    return this.office;
  }

  filterLog(filterVal:any){
   let res;
   if(this.dataSource != undefined){


    if(this.screen == "RecordLog"){
      res = this.dataSource.filter((item) =>
     item.Application.PatientName.toLowerCase().includes(filterVal)
   );
   return res;
    }


     if(this.screen == "view-apps"){
       res = this.dataSource.filter((item) =>

          item.ApplicationId.toUpperCase().includes(filterVal) ||
         item.PatientName.toLowerCase().includes(filterVal) ||
         item.Doctor.FirstName.toLowerCase().includes(filterVal) ||
         item.Doctor.Office.NickName.toLowerCase().includes(filterVal)
       );
       return res;
     }

     if(this.screen == "view-profiles"){
       res = this.dataSource.filter((item) =>
         item.FirstName.toLowerCase().includes(filterVal) ||
         item.Email.toLowerCase().includes(filterVal)
       );
       return res;
     }

       if(this.screen == "view-users"){
           res = this.dataSource.filter((item) =>
               item.userName.toLowerCase().includes(filterVal) ||
               item.password.toLowerCase().includes(filterVal)
           );
           return res;
       }


    }
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
