import {Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../../../services/adminServices/admin-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Office} from '../../../models/office';
import {DoctorService} from '../../../services/doctorServices/doctor-service.service';
import {LoginService} from '../../../services/login.service';
import {NotificationService} from '../../../providers/notification.service';



@Component({
  selector: 'app-new-office',
  templateUrl: './new-office.component.html'
})
export class NewOfficeComponent implements OnInit {

  office:Office = new Office();
  profileId:number;
  isMain:boolean=false;
  userId:number;
  roleId:number;
  isEdit:boolean=false;


  constructor(public activeModal: NgbActiveModal, private adminServiceService:AdminService,
              private route: ActivatedRoute,
              private router: Router,
              private doctorService:DoctorService,
              private  loginService:LoginService,
              private notifyService : NotificationService) {
    this.userId = loginService.getUserInfo().idUser;
    this.roleId = loginService.getUserInfo().RolId;

  }

  ngOnInit(): void {
  this.getCurrentProfile(this.userId);

  let officeModelEdit = this.doctorService.getOfficeModel();
  if(officeModelEdit != null){
    this.office = officeModelEdit;
    this.isEdit = true;
  }else{
    this.isEdit = false;
  }

  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  onSelect(isMain){
    this.isMain = isMain.target.checked;
  }

  create(){

    if(this.office.isMain === undefined){
      this.office.isMain = false;
    }
    if(this.office.Name != undefined){
   this.office.ProfileId = this.profileId;
   this.office.NickName = this.office.Name;
   this.doctorService.createOffice(this.office).subscribe(res => {
      if(res.idOffice > 0){
        this.activeModal.close(res.ProfileId);
      }
   });
    }else{
      this.notifyService.showError("You must to fill the information","Required");
    }
  }

  edit(){
    this.doctorService.updateOffice(this.office.idOffice,this.office).subscribe(res => {
      if(res){
        this.activeModal.close(res);
      }
    });
  }
  getCurrentProfile(userId){
    this.adminServiceService.getByUserId(userId).subscribe(res => {
      this.profileId = res.idProfile;
    });
  }

}
