import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../services/adminServices/admin-service.service';
import {LoginService} from '../services/login.service';
import {AlertService} from '..';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userId:number;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminServiceService:AdminService,
              private  loginService:LoginService,
              protected alertService: AlertService)
  {
    this.userId = loginService.getUserInfo().idUser;
  }

   profile: any;


  ngOnInit(): void {
    this.getByUserId();
  }

  getByUserId(){
    this.adminServiceService.getByUserId(this.userId).subscribe((data: {}) => {
      this.profile = data;
    });
  }


  save(){
    this.adminServiceService.updateProfile(this.profile.idProfile,this.profile).subscribe((res: {}) => {
       if(res === true){
           this.adminServiceService.updateAddress(this.profile.AddressId,this.profile.address).subscribe((address: {}) => {
             if(address === true){
               this.alertService.success("Changes Saved",{id:'alert-1',autoClose:this.options.autoClose});
            }
           });
       }
    });
  }

  desactivateAccount(){
    if(window.confirm('Are you sure, you want to desactivate your account?')){
    this.adminServiceService.desactivateAccount(this.profile.user.idUser,false).subscribe((res: {}) => {
      if(res === true){
       this.loginService.signOut();
      }
    });
     }
    }


}
