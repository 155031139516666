import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  getNotification:any;
  constructor(private toastr: ToastrService) { }

  showSuccess(message, title){
    this.toastr.success(message, title)
  }

  showError(message,title){
    this.toastr.error(message,title);
  }

  showWarning(message){
    this.toastr.warning(message);
  }

  showInfo(message,title){
    this.toastr.info(message,title);
  }

  show(message,title){
    this.toastr.show(message,title)
  }
  showHTMLMessage(message, title){
    this.toastr.success(message, title, {
      enableHtml :  true
    })
  }
  showErrorHTMLMessage(message, title){
    this.toastr.error(message, title, {
      enableHtml :  true
    })
  }

//timespan example: 5000
  showSuccessWithTimeout(message, title, timespan){
    this.toastr.success(message, title ,{
      timeOut :  timespan
    })
  }

  getPushNotification(){
    return localStorage.getItem('notification');
  }

  sendPushNotification(message,title){
    this.getNotification = this.showSuccessWithTimeout(message, title, 6000);
  }

}
