import {AfterContentInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {Doctor} from '../../models/doctor';
import {NotificationService} from '../../providers/notification.service';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {User} from '../../models/user';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit, AfterContentInit {

  constructor(
              private route: ActivatedRoute,
              private router: Router,
              private adminServiceService:AdminService,
              private notifyService : NotificationService,
              private DoctorService:DoctorService,
              private clipboard: Clipboard) { }

  users = new Array<User>();
  appModel = new User();
   p: number = 1;
  itemsPPage:number=15;
  pagination: number = 0;
  offices:any[] = [];

  ngAfterContentInit(){
    this.getAllUsers();
  }

  ngOnInit(): void {
    this.getAllOfices();
  }

  getNewRecordLog(evt){
    this.users = evt;
  }
  getAllUsers(){

    if( this.users.length > 0){
      this.users = [];
    }
    this.adminServiceService.getAllUsers().subscribe((data: {}) => {

      if (data != null) {
        // @ts-ignore
        data.forEach(x => {

          this.appModel.userName = x.userName;
          this.appModel.password = x.password;
          this.appModel.idUser = x.idUser;

          if (this.offices.length > 0) {
            const dataTmp = this.offices.filter(o => o.UserId == x.idUser);
            if (dataTmp.length > 0) {

              const _idProfile = dataTmp.find(r => r.idProfile).idProfile;
              if(_idProfile > 0){
                const data = dataTmp.filter(r => r.idProfile == _idProfile)[0];
               const officeSelected = data.offices.find(t => t.isMain == true);
                if(officeSelected !== undefined){
                  this.appModel.mainOffice = officeSelected.Name;
                }
                else{
                  this.appModel.mainOffice = "N/A";
                }
              }
            }else{
              this.appModel.mainOffice = "N/A";
            }
          }


          if(x.RolId === 1){
            this.appModel.role = "Administrator";
          }else if(x.RolId === 2){
            this.appModel.role = "Doctor";
          }
          this.appModel.createdAt = x.createdAt;
          this.appModel.showPassword = false;
          if(x.isActive === true) {
            this.appModel.userActive = "Active";
          }else{
            this.appModel.userActive = "Inactive";
          }

          this.users.push(this.appModel);
          this.appModel = new User();

        });

         this.pagination = this.users.length;
        //-1 down and 1 is active
        this.DoctorService.dataSource = this.users.sort((a, b) =>(a.isActive === b.isActive ? 1 : -1));
        this.DoctorService.screen = "view-users";
      }



    });
  }

  getAllOfices():void {
    this.DoctorService.getAll().subscribe({
        next:(data) => {
              this.offices = data;
         }
    })

  }

  public togglePassword(app: User) {
    if(app.showPassword === false){
    app.showPassword = true;
    }else if(app.showPassword === true) {
      app.showPassword = false;
    }
  }

  public copyText(textToCopy: string){
    this.clipboard.copy(textToCopy);
    this.notifyService.showSuccess("Text Copied!!!","Success");
  }
}
