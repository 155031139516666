import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../providers/notification.service';
import {ChatService} from '../../services/chat/chat.service';
import {LoginService} from '../../services/login.service';
import {AdminChatService} from '../../services/chat/admin-chat.service';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {ChatDetail} from '../../models/ChatDetail';
import {AdminService} from '../../services/adminServices/admin-service.service';

@Component({
  selector: 'app-modal-chat',
  templateUrl: './modal-chat.component.html',
  styleUrls: ['./modal-chat.component.css']
})
export class ModalChatComponent implements OnInit {

  user:string;
  message:string = "";
  element: any;
  messages: Array<any> = [];
  showMessages = false;
  temp: any;
  roleId:number;
  chatDetailModel = new ChatDetail();

  //Scroll to the bottom
  public triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
    this._scrollToService.scrollTo(config);
  }

  constructor(public activeModal: NgbActiveModal,private notifyService : NotificationService,
              public _cs:ChatService,
              public  loginService:LoginService,
              public api:AdminChatService,
              private _scrollToService: ScrollToService,
              private adminService:AdminService) {


    this.user = this.loginService.usuario.nombre;
    this.roleId = this.loginService.getUserInfo().RolId;

    this.chatDetailModel.idUser = this.loginService.getUserInfo().idUser;
    this.chatDetailModel.uid = this.loginService.getUserInfo().uid;
   /* this._cs.loadMessages(this.loginService.usuario.uid)
      .subscribe( ()=>{
        setTimeout( () =>{
          this.element.scrollTop = this.element.scrollHeight;
        },20);

      });*/
  }

  ngOnInit(): void {
    this.element = document.getElementById('app-messages');
    //this.startChat();
    this.getMyConversations();
    this.getChatDetailByUserId(this.chatDetailModel.idUser);
  }

  closeModal() {
    //this.notifyService.showWarning("Shipping Label Requested");
    this.activeModal.close('Modal Closed');
    //last seen
    this.chatDetailModel.lastSeen = new Date();
    this.updateChatDetail(this.chatDetailModel.idUser, this.chatDetailModel);
  }

  send_message(){
    console.log(this.message);
    let msg = {
      senderId: this.api.currentUser.uid,
      senderName: this.api.currentUser.name,
      timestamp: new Date(),
      content: this.message
    };
    this.messages.push(msg);
    console.log('list', this.messages);
    this.message = "";
    this.api.pushNewMessage(this.messages).then(() => {
      console.log('sent');
      this.getMyConversations();
    });

    this.chatDetailModel.isMessageRead = true;
    this.chatDetailModel.lastSeen = new Date();
    this.updateChatDetail(this.chatDetailModel.idUser, this.chatDetailModel);
    /*if(this.message.length === 0){
      return;
    }
    this._cs.addMessage(this.message)
      .then(() => { this.message = ""; })
      .catch((err) => {console.log('error al enviar',err)});*/

  }


  startChat(){
    this.api.addNewChat().then(async () =>{
      let user = this.api.getAdminUser();
      let promise = await this.api.addConvo(user);
    });

  }


  getMyConversations(){
    let convo = [...this.api.currentUser.conversations];
    let find = convo.find(item => item.uid != this.api.currentUser.uid);
    if (find) { // Conversation Found
      this.api.getChat(find.chatId).subscribe(m => {
        this.temp = m;
        // set the services values
        this.api.chat = this.temp[0];
        this.showMessages = true;
        this.messages = this.api.chat.messages == undefined ? [] : this.api.chat.messages;
        setTimeout(() => {
          this.triggerScrollTo() //scroll to bottom
        }, 1000);
        return
      });
    }else{
      this.startChat();
    }

  }

  public updateChatDetail(userId:number, chatModel:any){
    this.adminService.updateChatByUserId(userId, chatModel).subscribe({
      next:(res)=>{
        console.log("SUCCESS!! " + res);
      }
    })
  }

  public getChatDetailByUserId(userId:number){
    this.adminService.getChatDetailByUserId(userId).subscribe({
      next:(res) =>{
        if(res.length > 0 ) {
          this.chatDetailModel.isMessageRead = res.isMessageRead;
          this.chatDetailModel.lastSeen = res.lastSeen;
        }else{
           this.adminService.createChatDetail(this.chatDetailModel).subscribe({
             next:(res)=>{
               console.log("SUCCESS!! " + res);
             }
           });
        }
      }
    });
  }

}
