export class Section {
  _id:string;
  type:string;
  text:string;
  isActive:boolean;
  isStatic:boolean;
  forkList:Array<forkList>;
  name:string;
  class:string;

  tempChildId:string;
}

export class forkList{
  id:string;
  value:string;
  forkChildList:Array<forkChild>;
  day:string;
}

export class forkChild{
  id:string;
  value:string;
  class:string;
  type:string;
}
