import { Address } from './address';
import {Office} from './office';

export class Doctor {
  idProfile:number;
  FirstName:string;
  LastName:string;
  Email:string;
  PhoneNumber:string;
  WebPage:string;
  photoUrl:string;
  AddressId:number;
  UserId:number;
  createdAt:Date;
  updatedAt:Date;
  StatusText:string;
  isActive:boolean;
  Address:Address;
  Office:Office;

}
