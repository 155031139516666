import { Component, OnInit } from '@angular/core';
import {ChatService} from '../../services/chat/chat.service';
import {LoginService} from '../../services/login.service';



@Component({
  selector: 'app-daal-chat',
  templateUrl: './daal-chat.component.html',
  styleUrls: ['./daal-chat.component.css']
})
export class DaalChatComponent implements OnInit {
  userId:number;
  roleId:number;
  user:string;
  mensaje:string = "";
  elemento: any;

  constructor(public _cs:ChatService,private  loginService:LoginService) {
    this.roleId = this.loginService.getUserInfo().RolId;
    this.userId = this.loginService.getUserInfo().idUser;

    this.user = this.loginService.usuario.nombre;

    /*this._cs.loadMessages()
      .subscribe( ()=>{
        setTimeout( () =>{
          this.elemento.scrollTop = this.elemento.scrollHeight;
        },20);

      } );*/

  }

  ngOnInit() {
    //this.elemento = document.getElementById('app-mensajes');
  }

  enviar_mensaje(){
   /* console.log(this.mensaje);
    if(this.mensaje.length === 0){
      return;
    }
    this._cs.addMessage(this.mensaje)
      .then(() => { this.mensaje = ""; })
      .catch((err) => {console.log('error al enviar',err)});
*/
  }

}
