import {Component, OnInit} from '@angular/core';
import {Doctor} from '../../models/doctor';
import {Office} from '../../models/office';
import {Application} from '../../models/application';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {NotificationService} from '../../providers/notification.service';
import {FileApiService} from '../../providers/file.api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../loading.service';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {saveAs} from '@progress/kendo-file-saver';
import {ModalTrackingComponent} from '../modal-tracking/modal-tracking.component';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-cases-delivered',
  templateUrl: './cases-delivered.component.html',
  styleUrls: ['./cases-delivered.component.css']
})
export class CasesDeliveredComponent implements OnInit {

  constructor(private adminServiceService:AdminService,
              private notifyService : NotificationService,
              private DoctorService:DoctorService,
              private fileService:FileApiService,
              private modalService: NgbModal,
              public loadingService: LoadingService,
	      private datePipe: DatePipe) { }

  applications = new Array<Application>();
  appModel = new Application();
  hasDownloaded:boolean = false;
  URL:string;
  p: number = 1;
  itemsPPage:number=20;
  ngOnInit(): void {
    this.getApplications();
  }

  getApplicationsBySearch(evt){
    this.applications = evt;
  }
  getApplications(): void{
    this.appModel.Doctor =  new Doctor();
    this.appModel.Doctor.Office = new Office();

    if( this.applications.length > 0){
      this.applications = [];
    }
    this.adminServiceService.getAllApplicationsDelivered().subscribe((data: {}) => {

      if (data != null) {
        // @ts-ignore
        data.forEach(x => {

          this.appModel.idApplication = x.idApplication;
          this.appModel.ApplicationId = x.ApplicationId;
          this.appModel.ProfileId = x.ProfileId;
          this.appModel.RolId = x.RolId;
          this.appModel.hasAdmin = x.hasAdmin;
          this.appModel.createdAt = x.createdAt;
          this.appModel.updatedAt = x.updatedAt;
          this.appModel.DateDelivered = x.DateDelivered;
          this.appModel.isActive = x.isActive;
          this.appModel.isDelivered = x.isDelivered;
          this.appModel.PatientName = x.PatientName;
          this.appModel.Doctor.FirstName = x.profile.FirstName;
          this.appModel.Doctor.LastName = x.profile.LastName;
          this.appModel.Doctor.Email = x.profile.Email;
          this.appModel.Doctor.PhoneNumber = x.profile.PhoneNumber;
          this.appModel.Doctor.Office.Name = x.office.Name;
          this.appModel.isInvoiced = x.isInvoiced !== null ? x.isInvoiced : false;


          if(x.DueDate != undefined){
            this.appModel.DueDateTime = new Date(x.DueDate);
          }

          if(x.trackingNumber != undefined){
            this.appModel.trackingNumber = x.trackingNumber;
          }

          if(x.office.NickName == null){
            this.appModel.Doctor.Office.NickName = "Default";
          }else{
            this.appModel.Doctor.Office.NickName = x.office.NickName;
          }

          if(x.status != undefined){
            this.appModel.statusText = x.status.Status;
          }
          this.appModel.isFileDownloaded = x.hasDownloaded;
          this.appModel.Doctor.WebPage = x.profile.WebPage;


          if (x.isITero === true) {
            this.appModel.isIteroText = "Yes";
          } else {
            this.appModel.isIteroText = "No";
          }

          if (x.isPickup === true) {
            this.appModel.isPickUpText = "Yes";
          } else {
            this.appModel.isPickUpText = "No";
          }

          if(x.isInLab !== undefined && x.isInLab === true){
            this.appModel.isInLab = x.isInLab;
          }else{
            this.appModel.isInLab = false;
          }

          this.applications.push(this.appModel);
          this.appModel = new Application();
          this.appModel.Doctor =  new Doctor();
          this.appModel.Doctor.Office = new Office();
        });

        // this.pagination = this.doctors.length;
        this.DoctorService.dataSource = this.applications;
        this.DoctorService.screen = "view-apps";
      }
    });
  }

  delivered(idApplication: number, isDelivered: boolean) : void{
    if(isDelivered === false){
      isDelivered = true;
      this.deliverApp(idApplication,isDelivered);
      this.notifyService.showInfo("The application has been delivered","Info");
    }else{
      isDelivered = false;
      this.deliverApp(idApplication,isDelivered);
      this.notifyService.showInfo("The application has been retrieved","Info");
    }
  }

  public deliverApp(idApplication:number,isDelivered :boolean ): void{
    this.adminServiceService.deliverApp(idApplication,isDelivered).subscribe(res =>{
      if(res){
        this.getApplications();
      }
    });
  }

  getDocumentsByApplicationId(idApplication: number, applicationId: string, doctorName: any, app: { isFileDownloaded: boolean; ProfileId: number; }): void{

    if(app.isFileDownloaded === false){

      this.adminServiceService.isDownloaded(idApplication, true).subscribe(res =>{
        if(res){
          app.isFileDownloaded = true;
        }
      });
    }

    this.adminServiceService.getUserByProfileId(app.ProfileId).subscribe({
      next:(res)=>{
        this.URL = this.fileService.serverURL + res.user.userName + "/"+ applicationId + "/" + applicationId+'.zip';
        saveAs(this.URL, applicationId);
      }
    });
    this.notifyService.showHTMLMessage("Files Downloaded from AppId: " + applicationId,"Success");
  }

  removeApp(idApplication: number): void{
    if(window.confirm('Are you sure, you want to remove the current application?')) {
      this.adminServiceService.desactivateApplication(idApplication).subscribe((data) => {
        if(data === true){
          this.notifyService.showSuccess("Application Removed","Success");
          this.getApplications();
        }
      });
    }
  }


  openTrackingModal(idApplication: string): void {
    const modalRef = this.modalService.open(ModalTrackingComponent, {size: 'lg'});
    modalRef.componentInstance.idApplication = idApplication;
    modalRef.result.then(() => {
      this.getApplications();
    }).catch((error) => {
      console.log(error);
    });
  }//end tracking modal

  updateDueDate(event:any, applicationId:number): void{
    let date = new Date(event.target.value+"T12:00:00Z");
    this.adminServiceService.updateDueDate(date,applicationId).subscribe(res =>{
      if(res){
        this.notifyService.showSuccess("The date has been changed","Success");
        this.getApplications();
      }
    });
  }

  generateInvoiceApp(applicationId: number):void{
    this.adminServiceService.updateInvoice(applicationId).subscribe(res =>{
      if(res){
        this.notifyService.showSuccess("the application is marked as invoiced","Success");
        this.getApplications();
      }
    });
  }
 getInvoiceDate(postedInvoiceDate: any):string{
    return this.datePipe.transform(postedInvoiceDate, "yyyy-MM-dd");
  }

  showInvoiceMessage(postedInvoiceDate: any):void{

    const date = this.getInvoiceDate(postedInvoiceDate);
    this.notifyService.showInfo("the application has invoiced : " + date,"Information");

  }

}
