import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApplicationComponent} from '../doctor/application/application.component';
import {Application} from '../models/application';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  @ViewChild(ApplicationComponent) app: ApplicationComponent;
  userId:number;
  @Output() hasShipped = new EventEmitter<boolean>();
  @Input() data = new Array<Application>();

  constructor() { }

  ngOnInit(): void {
  }

  getGridData(gridData: any){
    this.data = gridData;
  }

}
