import { Component, OnInit } from '@angular/core';
import {AuthService, GoogleLoginProvider} from 'angular4-social-login';
import { ActivatedRoute, Router } from '@angular/router';
import {GlobalConstants} from '../common/global-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private _socioAuthServ:AuthService,private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
  }

  title = 'daalortho-app';
  user:any;
  userId:number = 0;


  //method to actually signing with google
  signIn(platform:string):void{
    platform = GoogleLoginProvider.PROVIDER_ID;
    this._socioAuthServ.signIn(platform).then((response) => {
      console.log(platform + "logged in user data is=", response);
      this.user = response;
    });
  }

  //sign Out method
  signOut():void{
    this._socioAuthServ.signOut();
    console.log("User Signed Out");
    location.reload();
  }

  back(role):void {
    //this.userId = GlobalConstants.role = role;
   //this.router.navigate(['/home',this.userId]);



   /* if(user === this.tmpUser){
      this.userId = 1;
    }else{this.userId = 2;}*/

    //this.router.navigate(['/index']);
  }

}
