import {Component, EventEmitter, Host, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import{DoctorService} from '../../services/doctorServices/doctor-service.service';
import {Documents} from '../../models/documents';
import {Application} from '../../models/application';
import * as JSZip from 'jszip';
import {LoginService} from '../../services/login.service';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {NotificationService} from '../../providers/notification.service';
import {Office} from '../../models/office';
import {map} from 'rxjs/operators';
import {FileApiService} from '../../providers/file.api.service';
import { saveAs } from 'file-saver';
import {LoadingService} from '../../loading.service';


@Component({
  selector: 'app-record-log',
  templateUrl: './recordLog.component.html',
  styleUrls: ['./record-log.component.css']
})
export class RecordLogComponent implements OnInit {
  userId:number;
  roleId:number;
  profileId:number;
  userName:string;
  p: number = 1;
  itemsPPage:number=20;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private doctorServiceService:DoctorService,
              private  loginService:LoginService,
              private adminServiceService:AdminService,
              private notifyService : NotificationService,
              private fileService:FileApiService,
              public loadingService: LoadingService) {

    this.userId = loginService.getUserInfo().idUser;
    this.roleId = loginService.getUserInfo().RolId;
    this.userName = loginService.getUserInfo().userName;

  }
  public recordLog = new Array<Documents>();
  documentModel = new Documents();
  groupRecord = new Array<Documents>();
  haveRecords:boolean=false;
  trackingNumber: string;
  isTrackingEnabled:boolean = false;
  showTrackingIcon:string = "Shipped by Mail (USPS)";

  getCurrentProfile(userId){
    this.adminServiceService.getByUserId(userId).subscribe(res => {
      this.profileId = res.idProfile;
      this.getApplicationsByProfileId(this.profileId);
    });
  }

  ngOnInit(): void {
     this.getCurrentProfile(this.userId);
     this.isTrackingEnabled = false;
  }

  getApplicationsByProfileId(profileId){
    this.doctorServiceService.getApplicationsByProfileId(profileId).subscribe(res =>{

      this.documentModel.Application = new Application();
      this.documentModel.Application.Office = new Office();

      this.groupRecord = res.count;



      res.rows.forEach(doc => {

        this.documentModel.Application.idApplication = doc.application.idApplication;
        this.documentModel.Application.ApplicationId = doc.application.ApplicationId;
        this.documentModel.Application.PatientName = doc.application.PatientName;
        this.documentModel.Application.isDelivered = doc.application.isDelivered;
        this.documentModel.Application.createdAt = doc.application.createdAt;
        this.documentModel.Application.hasAdmin = doc.application.hasAdmin;
        this.documentModel.Application.idStatus = doc.application.idStatus;
        this.documentModel.Application.DateDelivered = doc.application.DateDelivered;
        this.documentModel.Application.isInLab = doc.application.isInLab;
        this.documentModel.Application.trackingNumber = doc.application.trackingNumber;

        if(doc.application.status != undefined){
          this.documentModel.Application.statusText = doc.application.status.Status;
        }
        this.documentModel.Application.Office.Name = doc.application.office.Name;
        this.documentModel.idDocument = doc.idDocument;
        this.documentModel.Code = doc.Code;
        this.documentModel.Name = doc.Name;
        this.documentModel.Type = doc.Type;''
        this.documentModel.url = doc.url;
        this.documentModel.isLabSlip = doc.isLabSlip;
        this.documentModel.isActive = doc.isActive;
        this.documentModel.createdAt = doc.createdAt;
        this.documentModel.CountRows = 0;

        if(this.documentModel.Application.isDelivered === true && this.documentModel.Application.isInLab === true){
          this.documentModel.Application.isDeliveredText = "Delivered";
        }else{
          this.documentModel.Application.isDeliveredText = "In Progress";
        }

        if(this.documentModel.Application.isDelivered === false && this.documentModel.Application.isInLab === true){
          this.documentModel.Application.isDeliveredText = "In Lab";
        }

        if(doc.application.DueDate != undefined){
          let date = new Date(doc.application.DueDate);
          this.documentModel.Application.DueDateTime = date;

        }


        this.recordLog.push(this.documentModel);
        this.documentModel = new Documents();
        this.documentModel.Application = new Application();
        this.documentModel.Application.Office = new Office();

      });



      if(this.recordLog.length > 0){
        this.haveRecords = true;
      }else{this.haveRecords = false;}

      this.compare(this.recordLog,this.groupRecord);
      this.doctorServiceService.dataSource = this.recordLog;
      this.doctorServiceService.screen = "RecordLog";
    });
  }

  compare(target,source){
    target.forEach(key =>{
      source.forEach(outer =>{
        if(key.Application.idApplication === outer.IdApplication)
        {
          key.CountRows = (outer.count);
        }
      });
    });
  }

  getNewRecordLog(evt){
    this.recordLog = evt;
  }

  sendNotification(applicationId){
   this.download(applicationId);
    this.notifyService.showHTMLMessage("Files Downloaded from AppId: " + applicationId,"Success");
  }

  download(appId): void {
    let URL = this.fileService.serverURL+ this.userName + "/"+ appId + "/" + appId+'.zip';
    saveAs(URL, appId);
  }

  ShowTracking(isEnabled: boolean):void{
       if(isEnabled === false){
         this.isTrackingEnabled = true;
       }else{
         this.isTrackingEnabled = false;
       }

  }

}
