import {
  Directive,
  ElementRef,
  ViewChild,
  Renderer2,
  HostListener,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  AfterContentInit
} from '@angular/core';
import {Tags} from './web/structure/tag-models/tag-models';
import {DaalTemplateService} from './providers/daaltemplate.service';
import {forkChild, forkList, Section} from './web/structure/tag-models/section';
import {HandlerModel} from './web/structure/model/handler-model';
import {Observable} from 'rxjs';

@Directive({
  selector: '[appFormElement]'
})
export class FormElementDirective implements OnInit, OnDestroy, AfterContentInit{
  @ViewChild('inputText') inputText:ElementRef;
  private tags:Tags = new Tags();
  @Output() public handlerOutput = new EventEmitter<any>();
  StaticSections : Array<string> = new Array<string>();
  mainObjectResult: any;
  sourceChildsData:any;
  handlerModel:HandlerModel = new HandlerModel();

  ForkSubNode: Observable<{fork:forkList, targetCollection?:any}>;
  SubChildNode: forkList;
  childTargetCollection:any;

  constructor(private elmRef: ElementRef,
              private renderer: Renderer2,
              private daaltemplateService: DaalTemplateService) {


  }

  // @HostListener('mouseenter') onMouseEnter(){
  //   alert(this);
  // }

  // @HostListener('click', ['$event']) onClick(event: any) {
  //   window.alert('Host Element Clicked');
  //   console.log(event);
  // }

  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    /*target element is getting whole object*/
    const mainElement = targetElement['innerText'];
    const objectId = targetElement['id'];
    const childValue = (targetElement["value"] !==undefined ? targetElement["value"] : (targetElement['title'] !== undefined ? targetElement['title'] : undefined)); //applied only lists
    if(mainElement && objectId){
      let target:string = targetElement['localName'];
      // const result = this.tags.Types.includes(target);
      // if(result){
      this.handlerModel = new HandlerModel();
      if(childValue!=="" && childValue!==undefined){
        this.handlerModel.childId = childValue;
      }
      this.handlerModel.text = targetElement['innerText'];
      this.handlerModel.objectId = objectId;
      this.handlerModel.forkType = target;
      this.bindBack(this.handlerModel);
      //  }
    }
  }

  bindBack(handler:HandlerModel):void{
    this.handlerOutput.emit(handler);
  }

  ngAfterContentInit(){
    this.renderTabs();
  }

  /*Render each tab came from collections*/
  renderTabs():void{
    const sectionClassName = this.elmRef.nativeElement.className;
    if(this.isTemplateExists(sectionClassName)){
      this.daaltemplateService.getTemplateByCollectionName(sectionClassName).subscribe({
        next: (sourceChilds)=>{
          const mainObject = (<HTMLScriptElement[]><any>this.elmRef.nativeElement);
          if(sourceChilds.data.length > 0){
            this.mainObjectResult = mainObject;
            this.sourceChildsData = sourceChilds.data;
          }
        },
        complete:() =>{
          this.CompareChildElement(this.sourceChildsData, this.mainObjectResult);
        },
        error:(err)=>{
          console.log(err);
        }
      })
    }
  }

  ngOnInit() {
    /*temporary data*/
    this.handlerModel = new HandlerModel();
    this.StaticSections.push("newsletter-section");
    this.StaticSections.push("main-header style-one");
    this.StaticSections.push("main-footer banner-web");
    this.StaticSections.push("main-footer");
    this.StaticSections.push("shediul-section");

    /*creating observer*/
    this.ForkSubNode = Observable.create((observer) => {
      observer.next("1");
      observer.complete();
    });

  }
  ngOnDestroy() {
    // this.elmRef.nativeElement.removeEventListener('mouseenter', this.onMouseEnter);
  }

  onMouseEnter() {
  }

  isTemplateExists(className:string):boolean{
    return this.StaticSections.includes(className);
  }

  CompareChildElement(childs:Array<Section>, templateList:any):void{

    childs.forEach(child => {

      let getType = templateList.getElementsByTagName(child.type);
      if(getType !== undefined && child.isStatic){
        /*fetching the object forkList if exists for fetch arrays, lists, etc..*/
        if(child.forkList !== undefined &&  child.forkList.length > 0  && getType.length > 0){

          let listObject =  getType.namedItem(child.name);
          if(listObject !== undefined && listObject.childElementCount > 0){

            /*Assign list of childs type list i*/
            child.forkList.forEach(function(item, index){
              /*checking if current item have - sub nodes through class and type*/
            /*  let _forkChild = <Array<forkChild>>item["forkChild"];
              if(_forkChild !== undefined && _forkChild.length > 0){
                for(let childNode of _forkChild){
                  const parentNode = listObject.children[index];
                  const parentChild = listObject.children[index].children.namedItem(childNode.class);
                    parentNode.value = item.id;
                    parentNode.innerText = item.value;
                    parentNode.id = child._id + "-" + item.id;
                    if(parentChild){
                      const _parentNode = document.getElementById(parentNode.id);
                      let i = document.createElement("i");
                      let customType = document.createElement(childNode.type);
                      _parentNode.insertBefore(customType, _parentNode.firstElementChild);
                      _parentNode.insertBefore(i,_parentNode.firstElementChild);
                      _parentNode.children[0].setAttribute("style","border-top: 2px dashed #999;");
                      _parentNode.children[1].id = childNode.id;
                      _parentNode.children[1]["innerText"] = childNode.value;
                      _parentNode.children[1].className = childNode.class
                    }
                }
              }else{*/
              let childHours = listObject.children[index].children[item.day];
              if(childHours !== undefined){
                childHours.title = item.id;
                childHours.innerText = item.value;
                childHours.id = child._id;
              }else{
                listObject.children[index].value = item.id;
                listObject.children[index].innerText = item.value;
                listObject.children[index].id = child._id;
              }
           /*   }*/

            });
            this.AddOrUpdateTags("li");
            this.AddOrUpdateTags(child.type);
          }

        }else{
          /*checking if the same object is handled by same objectType such as array for assign by name and same child name*/
          const getChildNameElement = document.getElementsByClassName(child.class);
          if(getType.length > 0 && getChildNameElement !== null && getChildNameElement.length > 0){
            for(let _sourceIndexChild in getChildNameElement){
              const typeObject = getChildNameElement[_sourceIndexChild];
              if(child.type === typeObject.localName){
                typeObject['innerText'] = child.text;
                typeObject['id'] = child._id;
                this.AddOrUpdateTags(child.type);
              }
            }
          }else{
            if(getType.length > 0){
              getType[0].innerText = child.text;
              getType[0].id = child._id;
              this.AddOrUpdateTags(child.type);
            }
          }
        }

      }

    });
  }

  AddOrUpdateTags(type: string):void{
    if(this.tags.Types.includes(type) === false){
      this.tags.Types.push(type);
    }
  }

  UpdateChildText(childUpdated:Section){
    if(childUpdated._id !== undefined) {
      if (childUpdated.tempChildId) {
        const listTypes = document.getElementsByTagName(childUpdated.type);
        for(let _sourceIndexChild in listTypes){
          const typeObject = listTypes[_sourceIndexChild];
          let tempValue = (typeObject["value"] !== undefined) ? typeObject["value"] : (typeObject["title"] !== undefined ? typeObject["title"] : undefined);
          if(typeObject.id === childUpdated._id && tempValue && parseInt(tempValue) === parseInt(childUpdated.tempChildId)){
            typeObject["innerText"] = childUpdated.text;
            this.AddOrUpdateTags(childUpdated.type);
          }
        }

      } else {
        const mainObject = document.getElementById(childUpdated._id);
        if (mainObject !== undefined && childUpdated.isStatic) {
          mainObject.innerText = childUpdated.text;
          this.AddOrUpdateTags(childUpdated.type);
        }
      }
    }
  }



}
