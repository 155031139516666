import { Component, OnInit } from '@angular/core';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {LoginService} from '../../services/login.service';
import {NotificationService} from '../../providers/notification.service';
import {Office} from '../../models/office';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {of} from 'rxjs';
import {AlertService, NewProfileComponent} from '../..';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewOfficeComponent} from './new-office/new-office.component';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.css']
})
export class OfficeComponent implements OnInit {

  userId:number;
  roleId:number;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
    constructor(
    private doctorServiceService:DoctorService,
    private  loginService:LoginService,
    private adminServiceService:AdminService,
    private notifyService : NotificationService,
    private modalService: NgbModal,
    protected alertService: AlertService,
  ) {
    this.userId = loginService.getUserInfo().idUser;
    this.roleId = loginService.getUserInfo().RolId;
  }

  public offices = new Array<Office>();
  officeModel = new Office();

  getOfficesByUserId(userId){

    if(this.offices.length > 0){
       this.offices =[];
    }

    this.adminServiceService.getByUserId(userId).subscribe(res => {

      if(res != null){

        res.offices.forEach(office => {

          this.officeModel.idOffice = office.idOffice;
          this.officeModel.Name = office.Name;
          this.officeModel.isActive = office.isActive;
          this.officeModel.Address = office.Address;
          this.officeModel.Phone = office.Phone;
          this.officeModel.isMain = office.isMain;
          this.officeModel.ProfileId = office.ProfileId;

          if(office.isActive === true){
            this.officeModel.isActiveText = "Yes";
          }else{
            this.officeModel.isActiveText = "No";
          }

          if(office.isMain === true){
            this.officeModel.isMainText = "Yes";
          }else{
            this.officeModel.isMainText = "No";
          }

          this.offices.push(this.officeModel);
          this.officeModel = new Office();

        });

      }

    });
  }

  ngOnInit(): void {
    this.getOfficesByUserId(this.userId);
  }


  removeOffice(model){
    if(model.isActive === true){
      model.isActive = false;
    }else{
      model.isActive = true;
    }

    /*  this.doctorServiceService.updateOffice(model.idOffice,model).subscribe(res =>{
       if(res){
         this.notifyService.showSuccess("Office Updated","Success");
         this.getOfficesByUserId(this.userId);
       }
    });*/

 this.doctorServiceService.deleteOffice(model.idOffice).subscribe(res => {
     if(res === 1){
       this.notifyService.showSuccess("Office Removed","Success");
       this.getOfficesByUserId(this.userId);
     }else{
       this.notifyService.showError("The Office have Applications","Error");
     }
   });

  }

  openFormModal() {

    this.doctorServiceService.sendOfficeModel(null);
    const modalRef = this.modalService.open(NewOfficeComponent);

    modalRef.result.then((result) => {
      if(result != "Modal Closed"){
        this.alertService.success(" Office Created Correctly",{ id: 'alert-1' ,autoClose:this.options.autoClose});
        this.notifyService.showInfo("Office ready for use","Informative");
        this.getOfficesByUserId(this.userId);
      }
    }).catch((error) => {
      this.alertService.error("something went wrong ",{ id: 'alert-1' });
      this.notifyService.showError(error,"Error");
    });
  }

  openFormModalEdit(model) {

    this.doctorServiceService.sendOfficeModel(model);
    const modalRef = this.modalService.open(NewOfficeComponent);

    modalRef.result.then((result) => {
      if(result != "Modal Closed"){
        this.notifyService.showSuccess("Office updated","Success");
        this.getOfficesByUserId(this.userId);
      }
    }).catch((error) => {
      this.alertService.error("something went wrong ",{ id: 'alert-1' });
      this.notifyService.showError(error,"Error");
    });
  }








}
