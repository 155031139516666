import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LoginService} from '../../../services/login.service';
import {AdminService} from '../../../services/adminServices/admin-service.service';
import {Application} from '../../../models/application';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';



@Component({
  selector: 'app-nvar-header',
  templateUrl: './nvar-header-new.component.html',
  styleUrls: ['./nvar-header.component.css']
})


export class NvarHeaderComponent implements OnInit,AfterViewInit {

  constructor(private router:Router,
              private  loginService:LoginService,
              private adminServiceService:AdminService,
              private deviceService: DeviceDetectorService){


  }

  userId:number;
  photoUrl:any;
  roleId:number;
  notification:any;
  reason: string;
  itemsPPage:number=20;
  data = new Array<any>();
  headerName:string;
  isHeaderEnable:boolean = false;
  isResponsiveMode:boolean = false;

  @Output() gridData = new EventEmitter<Array<Application>>();

  ngOnInit(): void {
    this.roleId = this.loginService.getUserInfo().RolId;
    this.userId = this.loginService.getUserInfo().idUser;
     if(this.userId > 0){
       this.getProfile(this.userId);
       this.notification = JSON.parse(localStorage.getItem('notification'));
     }
     this.getNote();

    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      this.isResponsiveMode = true;
    }


  }

  ngAfterViewInit() {
  }

  search(value:string){
    this.router.navigate(['/search',value]);
  }

  signOut():void{

    this.loginService.signOut();
   // this._socioAuthServ.signOut();
    //this.router.navigate(['/login']);
  }

  getProfile(userId){
    this.adminServiceService.getByUserId(userId).subscribe(res => {
       this.photoUrl = res.photoUrl;
    });
  }

  getNote(): void{
    this.adminServiceService.getNote().subscribe({
      next:(res) => {
        if(res.length > 0 && res[0]["note"] !== ''){
          this.reason = res[0]["note"];
        }else{
          this.reason = "";
        }
      }
    });
  }

  getNewRecordLog(evt){
    this.data = evt;
    this.gridData.emit(this.data);
  }



}
