import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import * as firebase from 'firebase';
import {AdminChatService} from '../../../services/chat/admin-chat.service';
import {AdminService} from '../../../services/adminServices/admin-service.service';
import {LoginService} from '../../../services/login.service';
import {ChatDetail} from '../../../models/ChatDetail';

@Component({
  selector: 'conversation',
  templateUrl: './conversation.html',
  styleUrls: ['./conversation.css']
})
export class ConversationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isShowMessages = false;
  @Input() userSelected:any;
  public messages: Array<any> = [];
  message: string = '';
  userId:number;
  chatDetailModel = new ChatDetail();
  constructor(private  loginService:LoginService, private _scrollToService: ScrollToService, public api:AdminChatService, private adminService:AdminService){
    this.userId = this.loginService.getUserInfo().idUser;
  }
    ngOnDestroy (){
      //this.chatDetailModel = new ChatDetail();
    }
  ngOnInit() {
     //this.chatDetailModel.uid = this.userSelected.uid;
  }

    ngOnChanges(changes: SimpleChanges) {
      // this.getChatDetailByUid(this.chatDetailModel.uid);
        if(this.userSelected !== undefined){
          this.chatDetailModel = new ChatDetail();
          this.chatDetailModel.uid = this.userSelected.uid;
          if(this.userSelected.isMessageRead){ this.userSelected.isMessageRead = false; }
          this.getChatDetailByUid(this.chatDetailModel.uid);
            this.loadUser(this.userSelected).then();


        }
    }

  //Selecting A User from the list (onclick)  to talk
  async loadUser(user: { uid: any; }) {

    if (this.api.currentUser.conversations == undefined) {
      //means user has no conversations.
      this.api.currentUser.conversations = [];
    }
    let convo = [...this.api.currentUser.conversations]; //spread operators for ensuring type Array.
    let find = convo.find(item => item.uid == user.uid); // Check If It's the same person who user has talked to before,
    if (find) { // Conversation Found
      this.api.getChat(find.chatId).subscribe(m => {
        // set the services values
        this.api.chat = m[0];
        this.messages = this.api.chat.messages == undefined ? [] : this.api.chat.messages;
        this.isShowMessages = true;
        setTimeout(() => {
          this.triggerScrollTo() //scroll to bottom
        }, 1000);
        return
      })
    } else {
      /* User is talking to someone for the very first time. */
      this.api.addNewChat().then(async () => { // This will create a chatId Instance.
        // Now we will let both the users know of the following chatId reference
        let b = await this.api.addConvo(user); //passing other user info
      })

    }
  }


    /* Sending a  Message */
    sendMessage() {
        // If message string is empty
        if (this.message == '') {
            alert('Enter message');
            return
        }
        //set the message object
        let msg = {
            senderId: this.api.currentUser.uid,
            senderName: this.api.currentUser.name,
            timestamp: new Date(),
            content: this.message
        };
        //empty message
        this.message = '';
        //update
        this.messages.push(msg);
        console.log('list', this.messages);
        this.api.pushNewMessage(this.messages).then(() => {
            console.log('sent');
        })

      this.chatDetailModel.isMessageRead = false;
      this.updateChatDetail(this.chatDetailModel.idUser, this.chatDetailModel);
    }


    //Scroll to the bottom
    public triggerScrollTo() {
        const config: ScrollToConfigOptions = {
            target: 'destination'
        };
        this._scrollToService.scrollTo(config);
    }

    // Firebase Server Timestamp
    get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    /*getting only the userId*/
    public getChatDetailByUid(uid:string){
      this.adminService.getChatDetailByUid(uid).subscribe({
         next:(res) =>{
           if(res && res.length > 0) {
             const data = res[0];
             this.chatDetailModel.idUser = data.idUser;
             this.chatDetailModel.lastSeen = data.lastSeen;
           }
         },
        complete:()=>{
          this.chatDetailModel.isMessageRead = this.userSelected.isMessageRead;
          this.updateChatDetail(this.chatDetailModel.idUser, this.chatDetailModel);
        }
      });
    }


  public updateChatDetail(userId:number, chatModel:any){
      if(userId !== undefined && userId > 0){
        this.adminService.updateChatByUserId(userId, chatModel).subscribe({
          next:(res)=>{
            console.log("SUCCESS!! " + res);
          }
        })
      }
  }

}
