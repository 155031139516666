import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, GoogleLoginProvider} from 'angular4-social-login';
import {User, UserLogin} from '../../models/user';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {Doctor} from '../../models/doctor';
import {Address} from '../../models/address';
import {AlertService} from '../../index';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChatService} from '../../services/chat/chat.service';
import {LoginService} from '../../services/login.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  loginForm:FormGroup;
  constructor(
              private route: ActivatedRoute,
              private router: Router,
              private adminServiceService:AdminService,
              protected alertService: AlertService,fb: FormBuilder, private loginService:LoginService) {

    this.loginForm = fb.group({
      'userName': [null, Validators.required],
      'password': [null, Validators.required]
    });


  }
  ngOnInit(): void {

  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.login();
    }
  }

  login(){

    this.markFormTouched(this.loginForm);
    if(this.loginForm.valid){
      let formValues = this.loginForm.getRawValue();
      this.loginService.singleLogin(formValues.userName, formValues.password);
    }
  else{
      this.alertService.warn("Please fill the input texts",{id:'alert-1',autoClose:this.options.autoClose});
    }
  }

  signIn(platform:string):void{
      this.loginService.login();
  }
  markFormTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); }
    });
  };

}
