import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {LoginService} from '../../services/login.service';
import {NotificationService} from '../../providers/notification.service';
import {Office} from '../../models/office';

@Component({
  selector: 'app-view-offices',
  templateUrl: './view-offices.component.html',
  styleUrls: ['./view-offices.component.css']
})
export class ViewOfficesComponent implements OnInit {
  officeModel:Office = new Office();
  public offices = new Array<Office>();
  userId:number;
  roleId:number;
  idProfile:number;
  constructor(public activeModal: NgbActiveModal,
              private doctorService:DoctorService,
              private  loginService:LoginService,
              private notifyService : NotificationService) {
    this.userId = loginService.getUserInfo().idUser;
    this.roleId = loginService.getUserInfo().RolId;
  }

  ngOnInit(): void {

    let currentOffice = this.doctorService.getOfficeModel();
     if(currentOffice != null){
       // @ts-ignore
       this.getOfficesByProfileId(currentOffice.idProfile);
     }

  }

  getOfficesByProfileId(idProfile){
    if(this.offices.length > 0){
      this.offices =[];
    }
    this.doctorService.getOfficesByProfileId(idProfile)
      .subscribe(offices =>{
        if(offices != null){

          offices.forEach(office => {

            this.officeModel.idOffice = office.idOffice;
            this.officeModel.Name = office.Name;
            this.officeModel.isActive = office.isActive;
            this.officeModel.Address = office.Address;
            this.officeModel.Phone = office.Phone;
            this.officeModel.isMain = office.isMain;
            this.officeModel.ProfileId = office.ProfileId;
            this.officeModel.NickName = office.NickName;

            this.offices.push(this.officeModel);
            this.officeModel = new Office();

          });

        }
      });
  }

  assignNickName(model){
    this.doctorService.updateNickNameByOffice(model.idOffice,model)
      .subscribe(res =>{
        // @ts-ignore
        if(res === 1){
          this.notifyService.showSuccess("NickName Assigned","Success");
        }else{
          this.notifyService.showErrorHTMLMessage("Something Went Wrong / Please Verify the correct information","Required");
        }
      });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }


}
