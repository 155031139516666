import {Injectable, ViewChildren} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {FormElementDirective} from '../form-element.directive';
import {Section} from '../web/structure/tag-models/section';
import {HandlerModel} from '../web/structure/model/handler-model';
@Injectable({
  providedIn: 'root'
})

export class DaalTemplateService {
  // Define API
  //apiURL = 'https://daalortho.dashboard.com/api/';
 apiURL = 'http://localhost:3000/api/';

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':'application/json'
    })
  };

  /*NEWS-LETTER SECTION*/
  getNewsLetter():Observable<any>{
    return this.http.get<any>(this.apiURL+'news/getNewsLetter')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  updateChildSection(objectId:string,newText:string):Observable<any>{
    const child = {objectId: objectId, text: newText}
    return this.http.put<any>(this.apiURL + 'news/updateChildById/',child, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };
  /*END NEWS LETTER SECTION*/

  /*TEMPLATE SECTION*/

  getMainTemplate():Observable<any>{
    return this.http.get<any>(this.apiURL+'template/getMainTemplate')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  publish(status:boolean):Observable<any>{
    return this.http.put<any>(this.apiURL + 'template/changeStatus/'+ status, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getChildData(childUpdated:Section):void{
    const formDirective = new FormElementDirective(null,null,null);
    formDirective.UpdateChildText(childUpdated);
  }

  updateChildSectionT(handlerModel:HandlerModel):Observable<any>{
    return this.http.put<any>(this.apiURL + 'template/updateChildById/',handlerModel, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getTemplateByCollectionName(sectionName:string):Observable<any>{
    return this.http.get<any>(this.apiURL+'template/getTemplateByCollectionName/'+ sectionName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };

  getSection(objectId:string, collectionName:string):Observable<any>{
    return this.http.get<any>(this.apiURL+'template/getSection/'+ objectId +'/'+ collectionName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  };


  /*END TEMPLATE SECTION*/


  // Error handling
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
