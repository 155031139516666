import { Component, OnInit } from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isResponsiveMode:boolean = false;
  constructor(private router:Router,  private deviceService: DeviceDetectorService) {
    router.events.subscribe((val) => {
      if (val instanceof ActivationEnd) {
        //console.log('componentName', val.snapshot.component['name']);
        this.assignHeaderName(val.snapshot.component['name']);
      }
    });
  }

  crumbs: any =[];

  ngOnInit(): void {
    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      this.isResponsiveMode = true;
    }
  }

  assignHeaderName(componentName: string){
    if(componentName !== "IndexComponent")
      switch (componentName){
        case "ViewAppsComponent":   this.crumbs= [ { "title": "Dashboard", "link": "index/view-apps" },{ "title": "View Cases", "link": "/index" }]; break;
        case "ViewUsersComponent": this.crumbs= [ { "title": "Dashboard", "link": "index/view-apps" },{ "title": "View Users", "link": "/index" }]; break;
        case "ViewProfilesComponent": this.crumbs= [ { "title": "Dashboard", "link": "index/view-apps" },{ "title": "View Doctors", "link": "/index" }]; break;
        default: this.crumbs= [ { "title": "Dashboard", "link": "#" }]; break;
      }

    // if(componentName !== "IndexComponent")
    //   switch (componentName){
    //     case "ViewAppsComponent": this.headerName = "View Cases"; this.isHeaderEnable = true; break;
    //     case "ViewUsersComponent": this.headerName = "View Users"; this.isHeaderEnable = true; break;
    //     case "ViewProfilesComponent": this.headerName = "View Doctors"; this.isHeaderEnable = true; break;
    //     default: this.headerName = ""; this.isHeaderEnable = false; break;
    //   }
  }

}
