import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {Application} from '../../models/application';
import {Documents} from '../../models/documents';
import {Doctor} from '../../models/doctor';
import {LoginService} from '../../services/login.service';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {NotificationService} from '../../providers/notification.service';
import {Office} from '../../models/office';
import {Status} from '../../interface/status.interface';
import {HttpClient, HttpParams} from '@angular/common/http';
import {flatMap, map} from 'rxjs/operators';
import {interval} from 'rxjs';
import {FileApiService} from '../../providers/file.api.service';
import {AlertService} from '../..';
import {LoadingService} from '../../loading.service';


@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  uploadedFiles =[];

  @Output() _userId:EventEmitter<any> = new EventEmitter<any>();
  userId:number;
  roleId:number;
  @Input() isShippingLabel:boolean=false;
  @Output() sendNotification = new  EventEmitter<any>();
  applicationModel = new Application();
  documents = new  Array<Documents>();
  doctors = new Array<Doctor>();
  doctorModel:Doctor = new Doctor();
  offices = new Array<Office>();
  officeModel = new Office();
  status = new Status();
  public idStatus:number;
  listStatus = new Array<Status>();
  hasFiles:boolean=false;
  patientName:string;
  profileId:number;
  OfficeId:number;
  userName:string;
  hasOffice:boolean=false;
  disableButton: boolean = false;
  public minDate:Date = new Date();
  dueDate: any;

   constructor(private route: ActivatedRoute,
              private router: Router,
              private doctorServiceService:DoctorService,
              private  loginService:LoginService,
              private adminServiceService:AdminService,
              protected alertService: AlertService,
              private notifyService : NotificationService,
              private ngZone: NgZone,
              private fileApi:FileApiService,
              public loadingService: LoadingService) {

    this.userId = loginService.getUserInfo().idUser;
    this.roleId = loginService.getUserInfo().RolId;
    this.userName = loginService.getUserInfo().userName;
  }

  ngOnInit(): void {

    if(this.roleId === 1){
    this.getDoctorsList();
    }

    if(this.roleId === 2){
      this.getCurrentProfile(this.userId);

    }

    this.getStatus();

  }

 getCurrentProfile(userId){
    this.adminServiceService.getByUserId(userId).subscribe(res => {
      this.profileId = res.idProfile;
      this.getOfficesByProfileId(this.profileId);
    });
  }

 create():void{

     this.disableButton = true;
     if(this.profileId != undefined){
       this.applicationModel.ProfileId = this.profileId;
     }

     if(this.OfficeId!= undefined){
       this.applicationModel.OfficeId = this.OfficeId;
     }

     if(this.dueDate!=undefined){
        this.applicationModel.dueDate = this.dueDate;
     }

    this.applicationModel.RolId = this.roleId;
    this.applicationModel.idApplication = 0;
    this.applicationModel.PatientName = this.patientName;
    this.applicationModel.idStatus = this.idStatus;



   if(this.patientName != undefined){

   this.doctorServiceService.createApplication(this.applicationModel.ProfileId,this.applicationModel).subscribe({
     next: (response) => {
       this.applicationModel.idApplication = response.idApplication;
       this.applicationModel.ApplicationId = response.ApplicationId;
       this.sendNotification.emit(this.pushNotification(response));
       this.uploadDocuments(this.documents, this.applicationModel.ApplicationId);
       this.notifyService.showSuccess("Application Created","Success");

     },
     error: (error) => {
       throw error;
     },
     complete: () => {
       this.disableButton = false;
       this.notifyService.showWarning("Generating your package...");
       this.documents.forEach((doc) => {
         doc.url = this.userName + "/" + this.applicationModel.ApplicationId;
         this.doctorServiceService.createDocument(this.applicationModel.idApplication, doc).subscribe( {complete: () => {

             if(this.roleId === 1){
               this.ngZone.run(() => this.router.navigate(['/index/view-apps'])).then();

             }else if(this.roleId === 2){
               this.ngZone.run(() => this.router.navigate(['/index/recordLog'])).then();
               //this.notifyService.showSuccess("Application Created","Success");
             }
           }});
       });
     }
   });
    }// end of patient name

 }

  getDocuments(_documents: any) {
    this.documents = _documents;
    this.hasFiles = this.documents.length > 0;
  }

  getDoctorsList(){

    this.officeModel = new Office();
     this.doctorServiceService.getAll().subscribe(profiles => {

       profiles.forEach(profile => {
       this.doctorModel.idProfile = profile.idProfile;
         this.doctorModel.FirstName = profile.FirstName;
         this.doctorModel.LastName = profile.LastName;
         this.doctorModel.UserId = profile.UserId;

        if(profile.offices.length > 0){
         profile.offices.forEach(office => {
           this.officeModel.Name = office.Name;
           if(office.NickName == null){ this.officeModel.NickName = "Default"}
           else{
           this.officeModel.NickName = office.NickName;}
           this.officeModel.idOffice = office.idOffice;
           this.officeModel.ProfileId = office.ProfileId;
           this.officeModel.isMain = office.isMain;
           this.offices.push(this.officeModel);
           this.officeModel = new Office();
         });
        }

         this.doctors.push(this.doctorModel);
         this.doctorModel = new Doctor();


       });

     });
  }

  onSelectDoctor(value){

    let profileOffice = value.split(',');
    this.applicationModel.ProfileId = Number.parseInt(profileOffice[0]);
    this.applicationModel.OfficeId = Number.parseInt(profileOffice[1]);
    this.applicationModel.hasAdmin = true;

    this.adminServiceService.getUserByProfileId(this.applicationModel.ProfileId).subscribe(res => {
      this.userName = res.user.userName;
    });

  }

  onSelectOffice(OfficeId:number){
    this.applicationModel.OfficeId = OfficeId;

  }

  pushNotification(response: Application){
    localStorage.setItem('notification',JSON.stringify(response));
  }

  getStatus(){
     this.adminServiceService.getStatus().subscribe(status => {
        if(status != null){
              status.forEach(state => {
                this.status.idStatus = state.idStatus;
                this.status.Status = state.Status;
                this.listStatus.push(this.status);
                this.status = new Status();
              });
        }
     });
  }


  getOfficesByProfileId(profileId){
    this.doctorServiceService.getOfficesByProfileId(profileId).subscribe(res =>{
      if(res != null && res.length > 0){
          res.forEach(office => {
            this.officeModel.idOffice = office.idOffice;
            this.officeModel.ProfileId = office.ProfileId;
            this.officeModel.Name = office.Name;
            this.officeModel.isActive = office.isActive;
            this.officeModel.isMain = office.isMain;

            if(office.isMain === true){
              this.OfficeId = office.idOffice;
            }

            this.offices.push(this.officeModel);
            this.officeModel = new Office();
          });
        this.hasOffice = true;
      }else{
        this.hasOffice = false;
        this.alertService.warn("You must to create a new office",{id:'alert-1'});
      }
    });
  }


  /*CREATE DIRECTORY AND UPLOAD ON THE SERVER*/
  uploadDocuments(documents:Array<Documents>, appId:string){
    let formData = new FormData();
    for (let file of documents) {
      formData.append('files',file.File, file.Name);
    }

    //console.log(formData.getAll('files'));
    this.fileApi.createDirectory(this.userName, appId).subscribe({
      next: (item) => {
        console.log("next of directory => " + item);
        this.notifyService.showWarning("The documents are uploading...");
        this.fileApi.upload(formData, this.userName, appId).subscribe({
          complete: () => {
         //   setTimeOut(() => {
            this.notifyService.showSuccess("Upload completed correctly", "Success");
            console.log("documents uploaded => " + item);
            console.log("request done......");
         //   },100);
          },
          error: (item) => {
            console.log("directory =>" +item);
            this.notifyService.showError("Something went wrong, please contact the administrator", "Error");
          }
        });

      },
      error: (item) => {
        console.log("documents =>" +item);
        this.notifyService.showError("Something went wrong, please contact the administrator", "Error");
      }
    });

  }

}
