import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { map } from "rxjs/operators";
import {Message} from '../../interface/message.interface';
import {LoginService} from '../login.service';
import {AlertService} from '../..';
import {NotificationService} from '../../providers/notification.service';
//import {equal} from 'assert';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  //items: Observable<Item[]>;
  private itemsCollection:AngularFirestoreCollection<Message>;
  public chats: Message[] = [];
  private adminUID:string = "b8a2985c-ca65-476e-ab22-c2944f357ece";

  constructor(private afs: AngularFirestore, private _login:LoginService,  private notifyService : NotificationService){
  }




   loadMessages(uid?:string){
    //this.itemsCollection = this.afs.collection<Item>('items');
    //this.items = this.itemsCollection.valueChanges();
     //ref.where("uid","==",uid)

     this.itemsCollection = this.afs.collection<Message>('chats', ref => ref.orderBy('date','desc')
       .limit(20));


    return this.itemsCollection.valueChanges()
      .pipe(
        map((messages:Message[]) => {
          // this.chats = mensajes;
          this.chats = [];
          for(let message of messages){
            this.chats.unshift(message);
          }

          return this.chats;
        })
      );

  }

  addMessage(text:string){
    if(this._login.usuario.uid === undefined){
      //this.notifyService.showError("Something went wrong","error");
      this.notifyService.showErrorHTMLMessage("Please try to login in again, and don't reload the page","Required");
    }else{
    let message :Message = {
      name:this._login.usuario.nombre,
      message:text,
      date:new Date().getTime(),
      uid:this._login.usuario.uid
    };
    return this.itemsCollection.add(message);
    }
  }



}
