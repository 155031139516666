import { Component, OnInit } from '@angular/core';
import {HandlerModel} from '../../../../structure/model/handler-model';

@Component({
  selector: 'app-schedule-section',
  templateUrl: './schedule-section.component.html',
  styleUrls: ['./schedule-section.component.css','../../../categories.component.css']
})
export class ScheduleSectionComponent implements OnInit {

  public className: string = "shediul-section";
  public collectionName: string ="schedule";
  handlerModel:HandlerModel = new HandlerModel();
  constructor() { }

  ngOnInit(): void {
    this.handlerModel = new  HandlerModel();
  }
  getElementSelected(objectSelected):void{
    if(objectSelected){
      this.handlerModel = objectSelected;
      this.handlerModel.sectionName = this.className;
      this.handlerModel.collectionName = this.collectionName;
    }
  }

  getNewData($event){
    this.handlerModel.hasData = $event;
  }

  onClick(event){
    if(event !== undefined || event.data !=="" || (event.handlerModel !== undefined && event.handlerModel.text !== "")){
      this.handlerModel.hasData = true;
    }
  }

  onCancel(){
    this.handlerModel.text = "";
    this.handlerModel.hasData = false;
  }

}
