export class Office{
  idOffice:number;
  ProfileId:number;
  Name:string;
  Address:string;
  Phone:string;
  NickName:string;
  isActive:boolean;
  isMain:boolean;

  isActiveText:string;
  isMainText:string;

  }
