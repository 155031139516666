import {Doctor} from './doctor';
import {Office} from './office';
import { Status } from '../interface/status.interface';
import {User} from './user';
import {ProfileModel} from './profile-model';

export class Application {

  idApplication:number;
  ApplicationId:string;
  ProfileId: number;
  OfficeId: number;
  RolId: number;
  isActive:boolean;
  hasAdmin:boolean;
  idStatus:number;
  createdAt:Date;
  DateDelivered:Date;
  updatedAt:string;
  isIteroText:string;
  PatientName:string;
  isDelivered:boolean;
  isDeliveredText:string;
  isPickUpText:string;
  statusText:string;
  isInLab:boolean;
  trackingNumber:string;
  isFileDownloaded:boolean;
  dueDate:string;
  isInvoiced: boolean;
  DueDateTime:Date;
  postedInvoiceDate:Date;
  Doctor:Doctor;
  Office:Office;
  Status:Status;
  Profile:ProfileModel;
}
