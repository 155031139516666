import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../providers/notification.service';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {Doctor} from '../../models/doctor';
import {Address} from '../../models/address';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  profile = new Doctor();
  constructor(public activeModal: NgbActiveModal,private notifyService : NotificationService, private adminService:AdminService) { }

  ngOnInit(): void {

    this.profile.Address = new Address();
    this.adminService.getByUserId(1).subscribe(res => {

        this.profile.FirstName = res.FirstName;
        this.profile.LastName = res.LastName;
        this.profile.Email = res.Email;
        this.profile.PhoneNumber = res.PhoneNumber;
        this.profile.WebPage = res.WebPage;
        this.profile.Address.Address1 = res.address.Address1;
        this.profile.Address.Address2 = res.address.Address2;
    });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
