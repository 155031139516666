import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../providers/notification.service';
import {AdminService} from '../../services/adminServices/admin-service.service';

@Component({
  selector: 'modal-tracking',
  templateUrl: './modal-tracking.html'
})
export class ModalTrackingComponent implements OnInit {

   public trackingNumber:string;
   public idApplication:string;

constructor(public activeModal: NgbActiveModal,
              private notifyService : NotificationService,
              private adminServiceService:AdminService) {
  }

  ngOnInit(): void {
  this.getTrackingSaved();
  }

  closeModal() {
    //this.notifyService.showWarning("Shipping Label Requested");
    this.activeModal.close('Modal Closed');
  }

  save(){
    this.adminServiceService.updateTrackingNumber(this.idApplication, this.trackingNumber).subscribe({
      next:(res)=>{
        if(res){
          this.notifyService.showInfo("The tracking number has been updated correctly","Info");
        }
      },
      complete:()=>{
       // this.getApplications();
        this.closeModal();
      }
    });
  }

  getTrackingSaved():void{
   this.adminServiceService.getByApplicationByApplicationId(this.idApplication).subscribe({
     next:(res)=>{
       this.trackingNumber = res[0]["trackingNumber"];
     }
   })
  }

}
