import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DaalTemplateService} from '../../../providers/daaltemplate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../providers/notification.service';
import {HandlerModel} from '../model/handler-model';
import {Section} from '../tag-models/section';

@Component({
  selector: 'app-handler',
  templateUrl: './handler.component.html'
})
export class HandlerComponent implements OnInit {

  constructor(private daaltemplateService: DaalTemplateService) {
  }
  @Input() handler:HandlerModel;
  @Output() public setIsHasData = new EventEmitter<boolean>();
  public section: Section = new Section();

  ngOnInit(): void {
    this.handler = new HandlerModel();
    this.handler.hasData = false;
  }

  SetNewHasData(value:boolean):void{
    this.setIsHasData.emit(value);
  }

  updateChildSelected():void{
    /*update the value in the record and get again*/
    this.daaltemplateService.updateChildSectionT(this.handler).subscribe({
      next:(childUpdated)=>{
        if(childUpdated[0] !== undefined){
          /*this if is when we are retrieving an object updated by list array*/
          if(childUpdated[0].forkList && childUpdated[0].forkList.length > 0){
            const mainObject = childUpdated[0];
            const childToUpdate = mainObject.forkList[0];
            this.section._id = mainObject._id;
            this.section.tempChildId = childToUpdate.id;
            this.section.text = childToUpdate.value;
            this.section.isStatic = true;
            this.section.type = (childToUpdate.type !== undefined) ? childToUpdate.type :"li";
            this.daaltemplateService.getChildData(this.section);
            this.clear();
          }
        }else{
          this.daaltemplateService.getChildData(childUpdated);
          this.clear();
        }
      }
    });


 ;
  }

  /*clear the data after update*/
  clear():void{
    this.handler.text = "";
    this.handler.forkType = "";
    this.handler.collectionName = "";
    this.handler.sectionName = "";
    this.handler.childId = "";
    this.handler.objectId = "";
    this.handler.hasData = false;
    this.SetNewHasData(this.handler.hasData);
  }

  onCancel(){
    this.handler.text = "";
    this.handler.hasData = false;
    this.SetNewHasData(this.handler.hasData);
  }


}
