import {Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter} from '@angular/core';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {ActivatedRoute} from '@angular/router';
import {RecordLogComponent} from '../../doctor/record-log/record-log.component';
import {Documents} from '../../models/documents';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit,AfterViewInit {

  constructor(private doctorServiceService:DoctorService, private activatedRoute:ActivatedRoute) { }
  RecordLogC : RecordLogComponent;
  public typeSearch:any;
  public dataSource:any[] = [];
  value:string;
 @Output() newSource = new EventEmitter<Array<Documents>>();

  ngAfterViewInit() {

  }

  ngOnInit() {
  this.activatedRoute.params.subscribe(params => {
    this.value = params["value"];
    this.dataSource = this.doctorServiceService.filterLog(params["value"]);
  });

  }

  search(value){
    if(this.doctorServiceService.dataSource != null) {

      this.dataSource =  this.doctorServiceService.dataSource;
      let result = this.doctorServiceService.filterLog(value);
      if(result.length > 0){
      this.newSource.emit(result);
      }

    }
  }



}
