import {Component, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {Application} from '../../models/application';
import {Doctor} from '../../models/doctor';
import { saveAs } from 'file-saver';
import {NotificationService} from '../../providers/notification.service';
import {Office} from '../../models/office';
import {DoctorService} from '../../services/doctorServices/doctor-service.service';
import {FileApiService} from '../../providers/file.api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalTrackingComponent} from '../modal-tracking/modal-tracking.component';
import {LoadingService} from '../../loading.service';
import {Subscription} from 'rxjs';
import {CommonService} from '../../providers/common.service';
import {PaginationControlsComponent} from 'ngx-pagination/dist/pagination-controls.component';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-view-apps',
  templateUrl: './view-apps.component.html',
  styleUrls: ['./view-apps.component.css']
})
export class ViewAppsComponent implements OnInit, OnChanges, OnDestroy  {
  private subscriptionName: Subscription;
  //messageReceived: any;
  constructor(
              private adminServiceService:AdminService,
              private notifyService : NotificationService,
              private DoctorService:DoctorService,
              private fileService:FileApiService,
              private modalService: NgbModal,
              public loadingService: LoadingService,
              private service: CommonService,
	      private datePipe: DatePipe) {

       // subscribe to sender component messages
    // this.subscriptionName= this.service.getUpdate().subscribe
    // (message => { //message contains the data sent from service
    //   this.messageReceived = message;
    //   console.log(this.messageReceived);
    // });
  }

  applications = new Array<Application>();
  appModel = new Application();
  hasDownloaded:boolean = false;
  URL:string;
  p: number = 1;
  itemsPPage:number=20;
  showTrackingIcon:string = "Shipped by Mail (USPS)";


  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    //this.subscriptionName.unsubscribe();
  }
  ngOnInit(): void {
    this.getApplications();
  }
   formatDate(dt) {
    // const _date = new Date(dt);
    // const nameDay = this.weekday[dt.getDay()];
    // return nameDay + ', ' + dt.getDate() + ' ' + this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
    let normalizeHour = dt.getHours() >= 13 ? dt.getHours() - 12 : dt.getHours()
    return dt.getHours() >= 13 ? normalizeHour + 'PM' : normalizeHour + 'AM'
  }
  getApplications(){
    this.appModel.Doctor =  new Doctor();
    this.appModel.Doctor.Office = new Office();

    if( this.applications.length > 0){
      this.applications = [];
    }
    this.adminServiceService.getAllApplications().subscribe((data: {}) => {

      if (data != null) {
        // @ts-ignore
        data.forEach(x => {

          this.appModel.idApplication = x.idApplication;
          this.appModel.ApplicationId = x.ApplicationId;
          this.appModel.ProfileId = x.ProfileId;
          this.appModel.RolId = x.RolId;
          this.appModel.hasAdmin = x.hasAdmin;
          this.appModel.createdAt = x.createdAt;
          this.appModel.updatedAt = x.updatedAt;
          this.appModel.DateDelivered = x.DateDelivered;
          this.appModel.isActive = x.isActive;
          this.appModel.isDelivered = x.isDelivered;
          this.appModel.PatientName = x.PatientName;
          this.appModel.Doctor.FirstName = x.profile.FirstName;
          this.appModel.Doctor.LastName = x.profile.LastName;
          this.appModel.Doctor.Email = x.profile.Email;
          this.appModel.Doctor.PhoneNumber = x.profile.PhoneNumber;
          this.appModel.Doctor.Office.Name = x.office.Name;
          this.appModel.isInvoiced = x.isInvoiced !== null ? x.isInvoiced : false;
	  this.appModel.postedInvoiceDate = x.postedInvoiceDate;


          if(x.DueDate != undefined){
            let date = new Date(x.DueDate);
            this.appModel.DueDateTime = date;
           // console.log(this.formatDate(date));
          }

          if(x.trackingNumber != undefined){
            this.appModel.trackingNumber = x.trackingNumber;
          }

          if(x.office.NickName == null){
            this.appModel.Doctor.Office.NickName = "Default";
          }else{
            this.appModel.Doctor.Office.NickName = x.office.NickName;
          }

          if(x.status != undefined){
            this.appModel.statusText = x.status.Status;
          }
          this.appModel.isFileDownloaded = x.hasDownloaded;
          this.appModel.Doctor.WebPage = x.profile.WebPage;


          if (x.isITero === true) {
            this.appModel.isIteroText = "Yes";
          } else {
            this.appModel.isIteroText = "No";
          }

          if (x.isPickup === true) {
            this.appModel.isPickUpText = "Yes";
          } else {
            this.appModel.isPickUpText = "No";
          }

          if(x.isInLab !== undefined && x.isInLab === true){
            this.appModel.isInLab = x.isInLab;
          }else{
            this.appModel.isInLab = false;
          }

          this.applications.push(this.appModel);
          this.appModel = new Application();
          this.appModel.Doctor =  new Doctor();
          this.appModel.Doctor.Office = new Office();
        });

        // this.pagination = this.doctors.length;
        this.DoctorService.dataSource = this.applications;
        this.DoctorService.screen = "view-apps";
      }



    });
  }

  removeApp(idApplication){
    if(window.confirm('Are you sure, you want to remove the current application?')) {
      this.adminServiceService.desactivateApplication(idApplication).subscribe((data) => {
        if(data === true){
          this.notifyService.showSuccess("Application Removed","Success");
          this.getApplications();
        }
      });
    }
  }

  delivered(idApplication,isDelivered){
    if(isDelivered === false){
      isDelivered = true;
      this.deliverApp(idApplication,isDelivered);
      this.notifyService.showInfo("The application has been delivered","Info");
    }else{
      isDelivered = false;
      this.deliverApp(idApplication,isDelivered);
      this.notifyService.showInfo("The application has been retrieved","Info");
    }
  }

  public deliverApp(idApplication:number,isDelivered :boolean ){
    this.adminServiceService.deliverApp(idApplication,isDelivered).subscribe(res =>{
      if(res){
        this.getApplications();
      }
    });
  }

  getDocumentsByApplicationId(idApplication,applicationId,doctorName, app){

    if(app.isFileDownloaded === false){

      this.adminServiceService.isDownloaded(idApplication, true).subscribe(res =>{
        if(res){
          app.isFileDownloaded = true;
        }
      });
    }

    this.adminServiceService.getUserByProfileId(app.ProfileId).subscribe({
      next:(res)=>{
        this.URL = this.fileService.serverURL + res.user.userName + "/"+ applicationId + "/" + applicationId+'.zip';
        saveAs(this.URL, applicationId);
      }
    });
    this.notifyService.showHTMLMessage("Files Downloaded from AppId: " + applicationId,"Success");
  }

  ngOnChanges(changes: SimpleChanges){
    // if(this.subscriptionName == undefined){
    //   this.subscriptionName = new Subscription();
    // }
    // this.subscriptionName= this.service.getUpdateData().subscribe
    // (dataTarget => { //message contains the data sent from service
    //   this.applications = dataTarget.data;
    //   this.itemsPPage = dataTarget.data.length;
    //   this.p=  dataTarget.data.length;
    // });
  }
  getNewRecordLog(evt){
   this.applications = evt;
  }

   updateDueDate(event:any, applicationId:number): void{
    let date = new Date(event.target.value+"T12:00:00Z");
    this.adminServiceService.updateDueDate(date,applicationId).subscribe(res =>{
      if(res){
        this.notifyService.showSuccess("The date has been changed","Success");
        this.getApplications();
      }
    });
  }

  ngModelChange(event:any, applicationId:number){
    let date = new Date(event.target.value+"T12:00:00Z");
    this.adminServiceService.updateDateDelivered(date,applicationId).subscribe(res =>{
      if(res){
        this.notifyService.showSuccess("The date has been changed","Success");
        this.getApplications();
      }
    });
  }

  setInLabApplication(idApplication:number):void{
    this.adminServiceService.setInLabApplication(idApplication).subscribe({
      next:(res)=>{
        if(res){
        this.notifyService.showInfo("The status has been changed In Lab","Info");
        }
      },
      complete:()=>{
        this.getApplications();
      }
    });

  }


  openTrackingModal(idApplication:string) {
    const modalRef = this.modalService.open(ModalTrackingComponent,{ size: 'lg' });
    modalRef.componentInstance.idApplication = idApplication;
    modalRef.result.then((result) => {
      if(result != "Modal Closed"){

        // this.alertService.success(" User:     "+ result.userName  +"                 "+"  Temp Password:      "+ result.password,{ id: 'alert-1' });
        // this.notifyService.showInfo("Please share the credentials to the doctor","Informative");
        // this.notifyService.show("Please close the current credential dialog","Required");
        // this.notifyService.showSuccess("User Created","Success");
        // this.getAll();
      }
      this.getApplications();
    }).catch((error) => {
      //this.alertService.error("something went wrong ",{ id: 'alert-1',autoClose:this.options.autoClose });
      //this.notifyService.showError(error,"Error");
      console.log(error);
    });
  }//end tracking modal

  generateInvoiceApp(applicationId: number):void{
    this.adminServiceService.updateInvoice(applicationId).subscribe(res =>{
      if(res){
        this.notifyService.showSuccess("the application is marked as invoiced","Success");
        this.getApplications();
      }
    });
  }

  getInvoiceDate(postedInvoiceDate: any):string{
    return this.datePipe.transform(postedInvoiceDate, "yyyy-MM-dd");
}

showInvoiceMessage(postedInvoiceDate: any):void{

    const date = this.getInvoiceDate(postedInvoiceDate);
  this.notifyService.showInfo("the application has invoiced : " + date,"Information");

}


}
