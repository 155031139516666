import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {IndexComponent} from './index/index.component';

import {ApplicationComponent} from './doctor/application/application.component';
import {AuthGuard} from './login/auth.guard';
import {RecordLogComponent} from './doctor/record-log/record-log.component';
import {AppComponent} from './app.component';
import {ProfileComponent} from './profile/profile.component';
import {ViewAppsComponent} from './admin/view-apps/view-apps.component';
import {ViewProfilesComponent} from './admin/view-profiles/view-profiles.component';
import {ErrorTemplateComponent} from './error-template/error-template.component';
import {SignInComponent} from './login/sign-in/sign-in.component';
import {ProfileEditComponent} from './profile/profile-edit/profile-edit.component';
import {SearchBarComponent} from './body/search-bar/search-bar.component';
import {MultiAlertsComponent} from './common/multi-alerts/multi-alerts.component';
import {PushNotificationsComponent} from './common/push-notifications/push-notifications.component';
import {OfficeComponent} from './doctor/office/office.component';
import {DaalChatComponent} from './common/daal-chat/daal-chat.component';
import {SignUpComponent} from './login/sign-up/sign-up.component';
import {ExampleChatComponent} from './common/example-chat/example-chat.component';
import {ManageChatsComponent} from './admin/manage-chats/manage-chats.component';
import {EmailConfirmationComponent} from './common/email-confirmation/email-confirmation.component';
import {ChangePasswordComponent} from './common/change-password/change-password.component';
import {CategoriesComponent} from './web/categories/categories.component';
import {SectionsComponent} from './web/categories/sections/sections.component';
import {CropComponent} from './common/crop/crop.component';
import {SettingsComponent} from './admin/settings/settings.component';
import {NewLoginComponent} from './login/new-login/new-login.component';
import {ViewUsersComponent} from './admin/view-users/view-users.component';
import {NewDaalChatComponent} from './common/daal-chat/new-daal-chat.component';
import {CasesDeliveredComponent} from './admin/cases-delivered/cases-delivered.component';

const routes: Routes = [

  {path: '',component:NewLoginComponent},
  //{ path: '',component:SignInComponent},
  //{ path: 'login',component:SignInComponent},
  {path: 'login',component:NewLoginComponent},
  { path: 'sign-up',component:SignUpComponent},
  {path:'search/:value',component:SearchBarComponent},
  {path:'multi-alerts',component:MultiAlertsComponent},
   {
      path:'index',
     component:IndexComponent,
      children:[
        {
          path:'new-app',
          component:ApplicationComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always',
        },
        {
          path:'recordLog',
          component:RecordLogComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'office',
          component:OfficeComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers:'always'
        },
        {
          path:'profile',
          component:ProfileComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'view-apps',
          component:ViewAppsComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'view-users',
          component:ViewUsersComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'view-profiles',
          component:ViewProfilesComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'edit-profile/:id',
          component:ProfileEditComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'chat',
          component: NewDaalChatComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'categories',
          component: CategoriesComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'sections',
          component: SectionsComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'common/:id',
          component: CropComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'settings',
          component:SettingsComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path:'cases-delivered',
          component:CasesDeliveredComponent,
          canActivate:[AuthGuard],
          canActivateChild:[AuthGuard],
          runGuardsAndResolvers: 'always'
        }
      ]

   },
  { path: 'crop', component: CropComponent},
  { path: 'password', component: ChangePasswordComponent},
  { path:'confirmation/:email', component:EmailConfirmationComponent},
  {path: '404', component: ErrorTemplateComponent},
  {path: '**', redirectTo: '/404'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
