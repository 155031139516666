import {Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Doctor} from '../../models/doctor';
import { Address } from 'src/app/models/address';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/user';
import {NotificationService} from '../../providers/notification.service';
import {LoginService} from '../../services/login.service';
import { v4 as uuidv4 } from 'uuid';
import {AdminChatService} from '../../services/chat/admin-chat.service';

@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.css']
})
export class NewProfileComponent implements OnInit {

  profile:Doctor = new Doctor();
  user:User = new User();
  constructor(public activeModal: NgbActiveModal,
              private adminServiceService:AdminService,
              private loginService:LoginService,
              private route: ActivatedRoute,
              private apiChatService: AdminChatService,
              private router: Router,private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.profile.Address = new Address();
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }


  create(){

    if(this.profile.FirstName != undefined){

      if(this.profile.Email != undefined){
      this.user.userName = this.profile.FirstName.toLowerCase();
      this.user.password = Math.random().toString(36).toLowerCase();
      this.user.uid = uuidv4();
      this.adminServiceService.createUer(this.user).subscribe(data => {
      if(data.idUser > 0){
      this.profile.UserId = data.idUser;
      this.adminServiceService.desactivateAccount(data.idUser,true).subscribe(()=>{});
        /*create user profile for chat through fire base*/
        this.loginService.fireSingUp(this.profile.Email, this.user.password).then(res => {

          /*define user creation for firebase*/
          this.apiChatService.createUser(this.user.uid,
            {
              name: this.user.userName,
              email: this.profile.Email,
              uid: this.user.password,
              conversations: []
            }
          ).then(r => {
          });
          //console.log(res);
        });

      this.adminServiceService.createProfile(this.profile).subscribe(res =>{
      if(res.idProfile > 0){

        this.activeModal.close(this.user);
      }
    });
      }
      });
      }else{
        this.notifyService.showError("The Email is Required", "Required");
      }
    }else{
      this.notifyService.showError("You must to fill the data", "Required");
    }

  }


}
