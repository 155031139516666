import {Component, Input, OnInit} from '@angular/core';
import{DoctorService} from '../../services/doctorServices/doctor-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {Doctor} from '../../models/doctor';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import{NewProfileComponent} from '../new-profile/new-profile.component';
import {AlertService} from '../..';
import {NotificationService} from '../../providers/notification.service';
import {Office} from '../../models/office';
import {ViewOfficesComponent} from '../view-offices/view-offices.component';

@Component({
  selector: 'app-view-profiles',
  templateUrl: './view-profiles.component.html',
  styleUrls: ['./view-profiles.component.css']
})
export class ViewProfilesComponent implements OnInit {

  @Input() public alerts: Array<string> = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private route: ActivatedRoute, private router: Router,
              private doctorServiceService: DoctorService,
              private adminServiceService:AdminService,
              private modalService: NgbModal,
              protected alertService: AlertService,
              private notifyService : NotificationService) {


  }

  doctors = new Array<Doctor>();
  profileId:number;
  pagination: number = 0;
  itemsPPage:number=8;
  p: number = 1;
  @Input() name;


  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.profileId = Number(params.get('id'));
    });

    this.getAll();
  }

  getAll(){

    let doctorModel = new Doctor();
    doctorModel.Office = new Office();

    if( this.doctors.length > 0){
      this.doctors = [];
    }
    this.doctorServiceService.getAll().subscribe((data: {}) => {
      if (data != null) {
        // @ts-ignore
       data.forEach(x => {

         doctorModel.FirstName = x.FirstName;
         doctorModel.LastName = x.LastName;
         doctorModel.Email = x.Email;
         doctorModel.PhoneNumber = x.PhoneNumber;
         doctorModel.isActive = x.user.isActive;
         doctorModel.idProfile = x.idProfile;
         doctorModel.UserId = x.user.idUser;
          if (x.user.isActive === true) {
            doctorModel.StatusText = "Active";
          } else {
            doctorModel.StatusText = "Down";
          }


         x.offices.forEach(office => {
           if(office.isMain === true){
             doctorModel.Office.Name = office.Name;
           }
         });

         this.doctors.push(doctorModel);
         doctorModel = new Doctor();
         doctorModel.Office = new Office();
        });

        this.pagination = this.doctors.length;
	 //-1 down and 1 is active
        this.doctorServiceService.dataSource = this.doctors.sort((a, b) =>(a.isActive == b.isActive ? 1 : -1));
        this.doctorServiceService.screen = "view-profiles";
      }
    });
  }


  profileEdit(idProfile){
    this.router.navigate(['/index/edit-profile',idProfile]);
  }

  desactivateAccount(idUser,status){
    if(window.confirm('Are you sure, you want to do this action?')) {

      if (status === false) {
        status = true;
      } else {
        status = false;
      }

      this.adminServiceService.desactivateAccount(idUser, status).subscribe(res => {
        if (res === true) {
          this.getAll();
          this.notifyService.showSuccess("Changes Saved","Success");
        }
      });
    }
  }

  openFormModal() {
    const modalRef = this.modalService.open(NewProfileComponent);

    modalRef.result.then((result) => {
      if(result != "Modal Closed"){
      this.alertService.success(" User:     "+ result.userName  +"                 "+"  Temp Password:      "+ result.password,{ id: 'alert-1' });
      this.notifyService.showInfo("Please share the credentials to the doctor","Informative");
        this.notifyService.show("Please close the current credential dialog","Required");
      this.notifyService.showSuccess("User Created","Success");
      this.getAll();
      }
    }).catch((error) => {
      //this.alertService.error("something went wrong ",{ id: 'alert-1',autoClose:this.options.autoClose });
      //this.notifyService.showError(error,"Error");
      console.log(error);
    });
  }

  openModalOffices(model){
    this.doctorServiceService.sendOfficeModel(model);
    const modalOffice = this.modalService.open(ViewOfficesComponent);
    modalOffice.result.then((result) => {
      if(result != "Modal Closed"){

      }
    }).catch((error) => {
     // this.alertService.error("something went wrong ",{ id: 'alert-1',autoClose:this.options.autoClose });
     // this.notifyService.showError(error,"Error");
      console.log(error);
    });

  }


  getNewRecordLog(evt){
    this.doctors = evt;
  }


}
