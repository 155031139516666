export class User {
  userName:string;
  password:string;
  isActive:boolean;
  idUser:number;
  RolId:number;
  authToken:string;
  uid:string;
  createdAt:string;
  role:string;
  showPassword: boolean = false;
  userActive: string;
  mainOffice: string;
}

export class UserLogin{
  id:string;
  name:string;
  photoUrl:string;
  authToken:string;
  provider:string;
  uid:string;
  email:string;
}
