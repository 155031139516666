import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FileHandle} from '../../dnd.directive';
import {Documents} from '../../models/documents';
import {DomSanitizer} from '@angular/platform-browser';
import * as JSZip from 'jszip';
import {saveAs} from '@progress/kendo-file-saver';
import {AdminService} from '../../services/adminServices/admin-service.service';



@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  filesUrl= [];
  files: FileHandle[] = [];
  @Output() documentsList = new EventEmitter<any>();
  documents = new  Array<Documents>();


  constructor(private sanitizer: DomSanitizer, private api:AdminService){}

  ngOnInit() { }

  filesDropped(files: FileHandle[]): void {

    this.files = files;

    if(this.files != null){

      let documents = new  Array<Documents>();
      let documentModel = new Documents();
      this.files.forEach(document =>{

        documentModel.Name = document.file.name;
        documentModel.Type = document.file.type;
        documentModel.isLabSlip = false;
        documentModel.File = document.file;
		
	 if(documentModel.Name.includes('stl')){
       documentModel.Type = 'stl';
      }


        documents.push(documentModel);
        documentModel = new Documents();

      });

      this.documents = documents;
      this.documentsList.emit(this.documents);

    }

  }


  deleteAttachment(index) {
    this.files.splice(index, 1);
    this.documents.splice(index,1);
    this.documentsList.emit(this.documents);
  }

}