import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {LoginService} from '../../services/login.service';
import {AlertService} from '../..';
import {NotificationService} from '../../providers/notification.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  signUpForm:FormGroup;
  isExistsEmail:boolean=false;
  email:string;
  password:string="";
  newPassword:string="";
  idUser:number;
  userName:string;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminServiceService:AdminService,
              private loginService:LoginService,
              private notifyService : NotificationService,
              protected alertService: AlertService,
              fb: FormBuilder) {
    this.signUpForm = fb.group({
      'password': [null, Validators.required],
      'confirmPassword': [null, Validators.required],
    });
  }

  ngOnInit(): void {

  }
  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.validate();
    }
  }
  change(){
    const userModel = {
      userName: this.userName,
      password: this.password
    }
  this.adminServiceService.updateById(this.idUser,userModel).subscribe(res =>{
      if(res){
        this.notifyService.showHTMLMessage("Your new password has been updated correctly, you can login in again","Success");
        this.cancel();
      }
    });
  }
  cancel(){
    this.router.navigate(['/login']).then(() => {});
  }

  validate(){
    this.adminServiceService.isEmailExists(this.email).subscribe(res => {
       if(res){
         this.isExistsEmail = res;
         this.adminServiceService.getProfileByEmail(this.email).subscribe(res => {
            this.idUser = res.UserId;
            this.userName = res.user.userName;
         });
       }else{
         this.alertService.warn("The email are not registered yet",{id:'alert-1',autoClose:this.options.autoClose});
       }
    });
  }
}
