export class HandlerModel {
  objectId:string;
  text:string;
  oldText:string; //TODO under review as beta
  forkType:string;
  childId:string; //only used for lists
  hasData:boolean;
  sectionName:string;
  collectionName:string;
}
