import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {NotificationService} from '../../providers/notification.service';


@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.css']
})
export class PushNotificationsComponent implements OnInit {

  constructor(private notifyService : NotificationService) { }

  ngOnInit(): void {
  }
  showToaster(){
    this.notifyService.showSuccess("Data shown successfully !!", "Notification");
  }

  showHtmlToaster(){
    this.notifyService.showHTMLMessage("<h2>Data shown successfully !!</h2>", "Notification")
  }

  test(){
    this.notifyService.showError("test","error");
  }
}
