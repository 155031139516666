import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoginService} from '../../../services/login.service';
import {AdminService} from '../../../services/adminServices/admin-service.service';
import {NotificationService} from '../../../providers/notification.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewOfficeComponent} from '../../../doctor/office/new-office/new-office.component';
import {ContactComponent} from '../../../admin/contact/contact.component';
import {ModalChatComponent} from '../../../admin/modal-chat/modal-chat.component';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-nvar-left',
  templateUrl: './nvar-left.component.html',
  styleUrls: ['./nvar-left.component.css']
})
export class NvarLeftComponent implements OnInit {

  isInfo:boolean=false;
  flag:boolean=false;
  userId:number;
  roleId:number;
  isResponsiveMode:boolean = false;
  deviceInfo = null;
  constructor(
    private loginService:LoginService,
    private notifyService : NotificationService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService
   ) {
    this.roleId = this.loginService.getUserInfo().RolId;
    this.userId = this.loginService.getUserInfo().idUser;
    this.epicFunction();
   // location.reload();
  }

  ngOnInit(): void {

    if(this.deviceService.isMobile() || this.deviceService.isTablet()){
      this.isResponsiveMode = true;
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log("isMobile " + isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log("isTablet " + isTablet);  // returns if the device us a tablet (iPad etc)
    console.log("isDesktopDevice " + isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  mouseIn(vin:boolean){
    this.isInfo = vin;
  }

  mouseOut(vin:boolean){
    this.isInfo = vin;
  }
  openMenu():boolean{
    if(this.flag  === false){
      this.flag = true;
    return this.isInfo = true;
    }else{
      this.flag = false;
      return this.isInfo = false;
    }
  }

  openModal() {


    const modalRef = this.modalService.open(ContactComponent);
    modalRef.result.then((result) => {
    this.notifyService.showInfo("Modal Closed","Informative");
    }).catch((error) => {
      this.notifyService.showError(error,"Error");
    });


  }

  logout():void{
    this.loginService.signOut();
 }

 openChat(){
  const chatModal = this.modalService.open(ModalChatComponent);
   chatModal.result.then((result) => {
     this.notifyService.showInfo("Modal Closed","Informative");
   }).catch((error) => {
     this.notifyService.showError(error,"Error");
   });
 }


}
