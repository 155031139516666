import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/interface/User.interface';
import { Chat } from 'src/app/interface/Chat.interface';
import { AngularFireAuth } from 'angularfire2/auth';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {_Message} from '../../interface/message.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminChatService {


  private temp:any;
  public currentUser:User;
  public otherUser;
  public messages =[];
  public chat :Chat ={
    chatId:'',
    messages:[]
  };
  public conversationId;
  private user: { uid: string; name: string; email: string };

  constructor(private afs: AngularFirestore,private afAuth:AngularFireAuth) {}

  public login(email, password){
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
  }

  public signup(email, password){
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
  }


  createUser(uid, data) {
    return this.afs.doc('users/'+uid).set({
      uid: uid,
      name: data.name,
      email: data.email,
      conversations: data.conversations,
      timestamp: new Date()
    })
  }

  updateUser(id, data) {
    return this.afs.doc('users/' + id).update(data);
  }

  setCurrentUser(uid) {
    localStorage.setItem('uid', uid);

    this.afs.doc('users/' + uid).valueChanges().subscribe(resp => {
      this.temp = resp;
      this.currentUser =this.temp;
    }, err => { console.log('error', err) })
  }

  getCurrentUser() {
   return this.afs.doc('users/' + localStorage.getItem('uid')).valueChanges();
     }

  public getUsers() {
    return this.afs.collection<any>('users').snapshotChanges();
  }

  getChat(chatId){
    return this.afs.collection('conversations', ref=> ref.where('chatId','==',chatId)).valueChanges()
      .pipe(
        map((messages:_Message[]) => {
          // this.chats = mensajes;
          this.messages = [];
          for(let message of messages){
            this.messages.unshift(message);
          }

          return this.messages;
        })
      );
  }

  refreshCurrentUser(){
    this.afs.collection('users/'+ localStorage.getItem('uid')).valueChanges().subscribe(data=>{
      this.temp = data;
      this.currentUser = this.temp;
    })
  }

  async addConvo(user){
    //data to be added.
    let userMsg ={name:user.name, uid: user.uid,chatId: this.chat.chatId};
    let otherMsg={name:this.currentUser.name, uid: this.currentUser.uid, chatId:this.chat.chatId};
    //first set both references.
    let myReference = this.afs.doc('users/'+ this.currentUser.uid);
    let otherReference = this.afs.doc('users/'+ user.uid);
    // Updating my profile
    myReference.get().subscribe(d=>{
      let c=d.data();
      console.log('c',c);
      if(!c.conversations){
        c.conversations = [];
      }
      c.conversations.push(userMsg);
      return myReference.update({conversations: c.conversations})
    });
    // Updating Other User Profile
    otherReference.get().subscribe(d=>{
      let c=d.data();
      console.log('c',c);
      if(!c.conversations){
        c.conversations = [];
      }
      c.conversations.push(otherMsg);
      return otherReference.update({conversations: c.conversations})
    })

  }

  async addInitialConvo(user, sourceUser){
    //data to be added.
    let userMsg ={name:user.name, uid: user.uid,chatId: this.chat.chatId};
    let otherMsg={name:sourceUser.userName, uid: sourceUser.uid, chatId:this.chat.chatId};
    //first set both references.
    let myReference = this.afs.doc('users/'+ sourceUser.uid);
    let otherReference = this.afs.doc('users/'+ user.uid);
    // Updating my profile
    myReference.get().subscribe(d=>{
      let c=d.data();
      console.log('c',c);
      if(!c.conversations){
        c.conversations = [];
      }
      c.conversations.push(userMsg);
      return myReference.update({conversations: c.conversations})
    });
    // Updating Other User Profile
    otherReference.get().subscribe(d=>{
      let c=d.data();
      console.log('c',c);
      if(!c.conversations){
        c.conversations = [];
      }
      c.conversations.push(otherMsg);
      return otherReference.update({conversations: c.conversations})
    })

  }

  addNewChat(){
    const chatId =this.afs.createId();
    return this.afs.doc('conversations/'+ chatId).set({
      chatId: chatId,
      messages:[]
    }).then(()=> {
      this.chat = {
        chatId:chatId,
        messages:[]
      }
    })
  }

  pushNewMessage(list){
    console.log('this-chat-x-x-x-x-x-x-', this.chat);
    return this.afs.doc('conversations/'+this.chat.chatId).update(
      {messages: list}
    )
  }

  clearData(){
    localStorage.clear();
    this.messages =[];
    this.currentUser = {
      conversations:[],
      name:'',
      email:'',
      uid:'',
      lastMessage:'',
      isMessageRead:null
    };
    this.chat = null;
    this.temp = null;

  }

  getAdminUser(){
    return this.user = {
      email:"info@daalortho.com",
      name:"Admin",
      uid:"b8a2985c-ca65-476e-ab22-c2944f357ece"
    };
  }



}
