import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'new-daal-chat',
  templateUrl: './new-daal-chat.html',
  styleUrls: ['./new-daal-chat.css']
})
export class NewDaalChatComponent implements OnInit {

  showMessages = false;
  userSelected: any;
  constructor(){}

  ngOnInit() {
  }

  isMessageToShow(showMessages: any){
    this.showMessages = showMessages;
  }

  getUserSelected(user:any){
    this.userSelected= user;
  }

}

