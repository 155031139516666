import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor() { }
  @Input() userId:number;
  @Input() getNotification:any;
   notification:any;
  ngOnInit(): void {
     if(this.getNotification != null){
    this.notification = this.getNotification;
    this.notification.updatedAt = new Date (this.notification.updatedAt).getSeconds();
    }
  }

  onSelect(evt){
    if(evt.returnValue === true){
      localStorage.removeItem('notification');
      //location.reload();
    }
  }
}
