import {AfterViewInit, Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AdminChatService} from '../../../services/chat/admin-chat.service';
import {map} from 'rxjs/operators';
import {AdminService} from '../../../services/adminServices/admin-service.service';
import {ChatDetail} from '../../../models/ChatDetail';

@Component({
  selector: 'left-chat',
  templateUrl: './left-chat.html'
})
export class LeftChatComponent implements OnInit, OnChanges {

  users: Array<any>;
  userFilter ={name:''};
  userName:string="";
  photoUser: any;
  temp: any;
  //showMessages = false;
  public messages: Array<any> = [];

  chatDetailList = new Array(ChatDetail);

  @Output() showMessages = new EventEmitter<boolean>();
  @Output() currentUser = new EventEmitter<any>();
  constructor(public api:AdminChatService, private adminService:AdminService){}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }
  ngOnInit() {
    this.getAllUsers();
    this.getAllChats();
  }

  getAllUsers() {

    this.api.setCurrentUser(localStorage.getItem('uid'));//setting up the uid in the services for easy access.
    this.api.getUsers().pipe(
      map(actions => {
        return actions.map(a => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          return {...data}
        })
      })
    ).subscribe({
      next: (response) => {

        this.CheckEmptyMessages();
        this.getLatestMessages();
        this.loadChatDetail();

        this.users = response.filter((item)=>{
          let find = this.api.currentUser.conversations.find(el => el.uid == item.uid);
          if(!find){
            return item;
          }
        });

      }

    })
  }
  public getLatestMessages(){
    if (this.api.currentUser.conversations == undefined) {
      //means user has no conversations.
      this.api.currentUser.conversations = [];
    }
      this.api.currentUser.conversations.map(item => {
          this.api.getChat(item.chatId).subscribe(chat =>{
            let temp = chat[0];
            if(temp !== undefined && temp.messages !== undefined){
            let message = temp.messages.findLast(r => r);
             if(message !== undefined && message.content !== undefined){
                 item.lastMessage = message.content;
             }

            }
          });
      });
  }


  public CheckEmptyMessages(){
    if (this.api.currentUser.conversations == undefined) {
      //means user has no conversations.
      this.api.currentUser.conversations = [];
    }
    this.api.currentUser.conversations.map(item => {
      this.api.getChat(item.chatId).subscribe(chat =>{
        let temp = chat[0];

        if(temp['messages'] === undefined){
         // let message = temp.messages.findLast(r => r);
          let userData = {
            chatId: temp.chatId,
            senderId: item.uid,
            senderName: item.name,
            timestamp: new Date(),
            content: "Welcome to @Daalortho"
          };

          this.startDefaultConversation(userData);
          userData.chatId = null;
          userData.senderId = null;
          userData.senderName = null;
          userData.timestamp = null;
          userData.content = null;

          console.log(item);
          console.log(temp);
        }

      });
    });
  }


  public startDefaultConversation(user:any){

    //update
    this.messages.push(user);
    this.api.chat.chatId = user.chatId;
    this.api.pushNewMessage(this.messages).then(() => {
      console.log('WELCOME!!!');
      this.api.clearData();
    });
  }

  //Selecting A User from the list (onclick)  to talk
  async selectUser(user: { uid: any; }) {
        this.currentUser.emit(user);
        this.showMessages.emit(true);
  }

  public getAllChats(){
    this.adminService.getAllChats().subscribe({
      next:(res) =>{
        this.chatDetailList = res;
      }
    });
  }

  public loadChatDetail(){
          this.chatDetailList.forEach(chatDetail => {
        let chatD = chatDetail as unknown as ChatDetail;
        let find = this.api.currentUser.conversations.find(el => el.uid == chatD.uid);
          find.isMessageRead = chatD.isMessageRead;
      });


  }
}
