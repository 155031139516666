import {Component, OnInit} from '@angular/core';
import {NgWizardConfig, NgWizardService, StepChangedArgs, THEME} from 'ng-wizard';
import {DaalTemplateService} from '../../../providers/daaltemplate.service';
import {NotificationService} from '../../../providers/notification.service';
import {Template} from '../../structure/model/template';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {

  template:Template = new Template();

  constructor(private ngWizardService: NgWizardService,private daaltemplateService: DaalTemplateService,
              private notifyService : NotificationService) { }

  config:NgWizardConfig = {
    selected: 4,
    theme: THEME.dots,
    toolbarSettings: {
      toolbarExtraButtons: [
        {
          text:'Save', class:'btn btn-primary', event:() =>{ this.publish(false); },
        },
        {
          text:'Publish', class:'btn btn-danger', event:() =>{ this.publish(true); },
        },
        {
          text:'Live', class:'btn btn-success', event:() =>{ this.live(); },
        }
      ]
    }
  }



  ngOnInit(): void {
    this.getMainTemplate();
   }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    console.log(args.step);
  }

  getMainTemplate():void{
    this.daaltemplateService.getMainTemplate().subscribe({
      next:(template)=>{
        this.template = template[0];
      }
    });
  }
  publish(status:boolean):void{
    this.daaltemplateService.publish(status).subscribe({
      complete:()=>{
        if(status === true){
        this.notifyService.showSuccess("The sections are published correctly","Success");
        }else{
          this.notifyService.showWarning("The sections has been saved as draft");
        }
      }
    });
  }

  live():void{
    this.notifyService.showSuccess("Starting Live....","Success");
    window.open(this.template.source,"_blank");
  }

}
