import { Component, OnInit } from '@angular/core';
import {NgWizardConfig, NgWizardService, StepChangedArgs, THEME} from 'ng-wizard';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  config:NgWizardConfig = {
    selected: 5,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        {
          text:'Save', class:'btn btn-primary', event:() =>{ console.log('Finished!!!'); },
        },
        {
          text:'Publish', class:'btn btn-danger', event:() =>{ console.log('Published!!!'); },
        },
        {
          text:'Live', class:'btn btn-success', event:() =>{ console.log('Live!!!'); },
        }
      ]
    }
  }

  constructor(private ngWizardService: NgWizardService) { }

  ngOnInit(): void {

  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    //console.log(args.step);
  }

}
