import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from './adminServices/admin-service.service';
import {HttpClient} from '@angular/common/http';
import {Doctor} from '../models/doctor';
import {User, UserLogin} from '../models/user';
import {AngularFireAuth} from 'angularfire2/auth';
import {Address} from '../models/address';
import * as firebase from 'firebase';
import { NgZone } from '@angular/core';
import {AlertService} from '..';
import {AdminChatService} from './chat/admin-chat.service';
import {FileApiService} from '../providers/file.api.service';
//#region of token
declare function generateToken(key):any;
//#end region


@Injectable(
  { providedIn: 'root' }
)
export class LoginService {
  public usuario:any = {};
  profile:Doctor = new Doctor();
  userModel:User = new User();
  userId:number = 0;
  user:UserLogin = new  UserLogin();

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private http: HttpClient,
              private urls: AdminService,
              private router: Router,
              private adminServiceService:AdminService,
              public afAuth: AngularFireAuth,
              private ngZone: NgZone,
              protected alertService: AlertService,
              private apiChatService:AdminChatService,
              private fileApi:FileApiService)
  {
    this.profile.Address = new Address();
    this.afAuth.authState.subscribe(user =>{
      console.log("user status:", user);
      if(!user){
        return;
      }
      this.usuario.nombre = user.displayName;
      this.usuario.uid = user.uid;
    });


  }
  // Send email verification when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        //this.router.navigate(['<!-- enter your route name here -->']);
        this.ngZone.run(() => this.router.navigate(['/index/new-app'])).then();
      })
  }
  /*SING UP THROUGH FIREBASE*/
  public fireSingUp(email, password){
   return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
   /* return this.afAuth.auth.createUserWithEmailAndPassword(email, password).then((result) => {
      this.SendVerificationMail().then(res => {
        console.log("Success " + res)
      }); // Sending email verification notification, when new user registers
    }).catch((error) => {
      window.alert(error.message)
    })*/
  }


  public signIn(userName: string, password: string): Observable<any> {
    //missing password yet
    return this.urls.geUserByUserName(userName,password);
  }

  singleLogin(userName:string, password:string){

      this.adminServiceService.geUserByUserName(userName, password).subscribe(res => {
        if(res != null){
          localStorage.setItem('token',generateToken(userName));
          localStorage.setItem('userInfo',JSON.stringify(res));


          this.usuario.nombre = res.userName;
          this.usuario.uid = res.uid;

          this.apiChatService.setCurrentUser(res.uid);

          switch (res.RolId) {
            case 1:
              this.ngZone.run(() => this.router.navigate(['/index/view-apps'])).then();
              break;
            case 2:
              this.ngZone.run(() => this.router.navigate(['/index/new-app'])).then();
              break;
          }
        }else{
          this.alertService.error("User doesn't exists or user/password incorrect",{id:'alert-1',autoClose:this.options.autoClose});
        }
      });

  }

  public getUserInfo(): any {
    if (localStorage.getItem('userInfo')) {
      return JSON.parse(localStorage.getItem('userInfo'));
    }else{
      return null;
    }
  }
  getCurrentProfile(userId){
    this.adminServiceService.getByUserId(userId);
  }

  login() {


    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(response =>{


      this.user.id = response.user.uid;
      this.user.name = response.user.displayName;
      this.user.photoUrl = response.user.photoURL;
      this.user.authToken = response.user.refreshToken;
      this.user.provider = response.credential.providerId;
      this.user.email = response.user.email;
      this.user.uid = response.user.uid;


      /*Checking if user exists on the database - otherwise create new one*/
      this.adminServiceService.geUserByUserName(this.user.id,null).subscribe(res => {

        if(res != null){
          if(res.idUser > 0){
            /*redirect to login page*/
            localStorage.setItem('token',response.user.refreshToken);
            localStorage.setItem('userInfo',JSON.stringify(res));
            this.apiChatService.setCurrentUser(res.uid);

            switch (res.RolId) {
              case 1:
                this.ngZone.run(() => this.router.navigate(['/index/view-apps'])).then();
                break;
              case 2:
                this.ngZone.run(() => this.router.navigate(['/index/new-app'])).then();
                break;
            }
          }
        }else{
          /*Verify if email has already exists*/
          this.adminServiceService.isEmailExists(this.user.email).subscribe(exists => {
            if(exists === false) {
              this.userModel = new User();
              this.userModel.userName = this.user.id;
              this.userModel.password = Math.random().toString(30).toLowerCase();
              this.userModel.authToken = this.user.authToken;
              this.userModel.uid = this.user.uid;

              /*define user creation for firebase*/
              this.apiChatService.createUser(this.userModel.uid,
                {
                  name: this.user.name,
                  email: this.user.email,
                  uid: this.user.uid,
                  conversations: []
                }
              ).then(r => {
              });


              this.adminServiceService.createUer(this.userModel).subscribe(data => {
                if (data.idUser > 0) {
                  this.profile.UserId = data.idUser;
                  this.profile.FirstName = this.user.name;
                  this.profile.LastName = "";
                  this.profile.Email = this.user.email;
                  this.profile.PhoneNumber = "";
                  this.profile.WebPage = "";
                  this.profile.photoUrl = this.user.photoUrl.trim();
                  this.userModel.RolId = data.RolId;

                  localStorage.setItem('token', response.user.refreshToken);
                  localStorage.setItem('userInfo', JSON.stringify(data));
                  const code = this.generateCode();
                  localStorage.setItem('code', code);
                  this.adminServiceService.sendEmailConfirmation(this.user.email, code).subscribe(res => {
                    if (res) {
                      this.alertService.success("Your verification code was already send at your inbox", {
                        id: 'alert-1',
                        autoClose: this.options.autoClose
                      });
                    }
                  });
                  this.apiChatService.setCurrentUser(this.user.uid);

                  this.fileApi.createDirectory(this.user.name).subscribe(() => {
                  });
                  this.adminServiceService.createProfile(this.profile).subscribe(res => {
                    if (res.idProfile > 0) {
                      switch (this.userModel.RolId) {
                        case 1:
                          this.ngZone.run(() => this.router.navigate(['/index/view-apps'])).then();
                          break;
                        case 2:
                          //this.ngZone.run(() => this.router.navigate(['/index/profile'])).then();
                          this.ngZone.run(() => this.router.navigate(['/confirmation/', this.user.email])).then();
                          break;
                      }
                    }
                  });
                }
              });

            }else{
              this.alertService.warn("The email already exists, please contact Administrator",{id:'alert-1',autoClose:this.options.autoClose});
            }
          }); /*end verify validation*/

        }
      });

    });




  }

  public signOut(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    this.apiChatService.clearData();
    this.user = new UserLogin();
    this.usuario = {};
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }

public generateCode():string{
  return Math.random().toString(36).slice(2);
}
}
