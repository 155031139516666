export class Address {
  idAddress:number;
  Address1:string;
  Address2:string;
  City:string;
  ZipCode:string;
  State:string;
  isActive:boolean;

}
