import {Directive, NgZone} from '@angular/core';

@Directive({
  selector: '[appWindowScrollDirective]'
})
export class WindowScrollDirectiveDirective {

  private eventOptions: boolean|{capture?: boolean, passive?: boolean};

  constructor(private ngZone: NgZone) { }

  ngOnInit() {

      this.eventOptions = true;

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll, <any>this.eventOptions);
    });
  }

  ngOnDestroy() { window.removeEventListener('scroll', this.scroll, <any>this.eventOptions);
    //unfortunately the compiler doesn't know yet about this object, so cast to any window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {

  };

}
