import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/adminServices/admin-service.service';
import {NotificationService} from '../../providers/notification.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public reason: string;
  constructor(private adminServiceService:AdminService,
              private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.get();
  }

  public  get(): void{
    this.adminServiceService.getNote().subscribe({
      next:(res) => {
         if(res.length > 0){
        this.reason = res[0]["note"];
         }else{
           this.reason = "";
         }
       }
    });
  }

  public save(): void {
    this.adminServiceService.updateNote(1, this.reason).subscribe({
      next:(res)=>{
        if(res){
          this.notifyService.showInfo("The Note has been updated correctly","Info");
        }
      }
      // complete:()=>{
      //   this.get();
      // }
    });
  }

  public clear(): void {
    this.reason = '';
    this.save();
  }

}
