import { Component, OnInit } from '@angular/core';
import {HelperService} from '../../services/helper/helper.service';
import {Router} from '@angular/router';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {AdminChatService} from '../../services/chat/admin-chat.service';
import {map} from 'rxjs/operators';
import * as firebase from 'firebase';

@Component({
  selector: 'app-manage-chats',
  templateUrl: './manage-chats.component.html',
  styleUrls: ['./manage-chats.component.css']
})
export class ManageChatsComponent implements OnInit {
  showFiller: boolean = false;
  users: Array<any>;
  public messages: Array<any> = [];
  temp: any;
  showMessages = false;
  message: string = '';
  userFilter ={name:''};
  photoUser: any;
  userName:string="";
  constructor(private helper: HelperService, private router:Router, private _scrollToService: ScrollToService, public api:AdminChatService) { }

  ngOnInit(): void {
    this.getAllUsers();
  }
  open(list) {
    this.helper.openDialog(list);
  }

  getAllUsers() {

    this.api.setCurrentUser(localStorage.getItem('uid'));//setting up the uid in the services for easy access.
    this.api.getUsers().pipe(
      map(actions => {
        return actions.map(a => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          return {...data}
        })
      })
    ).subscribe(data => {
      console.log('data', data);
      this.users = data.filter((item)=>{
        if (this.api.currentUser.conversations == undefined) {
          //means user has no conversations.
          this.api.currentUser.conversations = [];
        }
        let find = this.api.currentUser.conversations.find(el => el.uid == item.uid);
        if(!find){
          return item;
        }
      })
    })
  }

  //Selecting A User from the list (onclick)  to talk
  async selectUser(user) {



    if (this.api.currentUser.conversations == undefined) {
      //means user has no conversations.
      this.api.currentUser.conversations = [];
    }
    let convo = [...this.api.currentUser.conversations]; //spread operators for ensuring type Array.
    let find = convo.find(item => item.uid == user.uid); // Check If Its the same person who user has talked to before,
    if (find) { // Conversation Found
      this.api.getChat(find.chatId).subscribe(m => {
        this.temp = m;
        // set the services values
        this.api.chat = this.temp[0];
        this.messages = this.api.chat.messages == undefined ? [] : this.api.chat.messages;
        this.showMessages = true;
        setTimeout(() => {
          this.triggerScrollTo() //scroll to bottom
        }, 1000);
        return
      })
    } else {
      /* User is talking to someone for the very first time. */
      this.api.addNewChat().then(async () => { // This will create a chatId Instance.
        // Now we will let both the users know of the following chatId reference
        let b = await this.api.addConvo(user); //passing other user info
      })

    }
  }

  /* Sending a  Message */
  sendMessage() {
    // If message string is empty
    if (this.message == '') {
      alert('Enter message');
      return
    }
    //set the message object
    let msg = {
      senderId: this.api.currentUser.uid,
      senderName: this.api.currentUser.name,
      timestamp: new Date(),
      content: this.message
    };
    //empty message
    this.message = '';
    //update
    this.messages.push(msg);
    console.log('list', this.messages);
    this.api.pushNewMessage(this.messages).then(() => {
      console.log('sent');
    })
  }

  //Scroll to the bottom
  public triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
    this._scrollToService.scrollTo(config);
  }

  // Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }



}
